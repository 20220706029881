import { useGoogleLogin } from '@react-oauth/google'
import { setLoading, setAlert, setUser } from 'store'
import { requestHandler } from 'utils/requestHander'
import { useNavigate } from 'react-router-dom'
export default function BtnGoogle() {
	const navigate = useNavigate()
	const loginGoogle = useGoogleLogin({
		onSuccess: async response => {
			try {
				setLoading(true)
				const result = await fetch(
					`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`,
					{
						headers: {
							Authorization: `Bearer ${response.access_token}`,
							Accept: 'application/json'
						}
					}
				)
				const userData = await result.json()
				const userToSend = {
					email: userData.email,
					name: userData.name,
					avatar: userData.picture
				}
				const authRegiterResult = await requestHandler('auth_google', userToSend)
				setLoading(false)
				if (authRegiterResult.status !== true) {
					console.log(authRegiterResult)
					setAlert({
						message: 'Tenemos problemas para iniciar sesión con google',
						type: 'danger'
					})
					return
				}
				setUser(authRegiterResult.data)
				navigate('/home')
			} catch (error) {
				console.log(error)
				setLoading(false)
				setAlert({
					message: 'Tenemos problemas para iniciar sesión con Google',
					type: 'danger'
				})
			}
		},
		onError: error => {
			console.log(error)
			setAlert({
				message: 'Tenemos problemas para iniciar sesión con Google',
				type: 'danger'
			})
		}
	})
	return (
		<a
			href="#"
			className="login-google"
			onClick={e => {
				e.preventDefault()
				loginGoogle()
			}}>
			<img src={require('assets/img/google.png')} alt="Google" />
			Continuar con Google
		</a>
	)
}
