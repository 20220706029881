import { useSelector } from 'react-redux'
export default function Loader() {
	const loading = useSelector(state => state.globalFlags.loading)
	if (loading) {
		return (
			<div className="loader-container">
				<div className="loader"></div>
			</div>
		)
	}
}
