import { useState } from 'react'
import Input from 'components/Input'
import Btn from 'components/Btn'
import Link from 'components/Link'
import { setLoading, setAlert } from 'store'
import { requestHandler } from 'utils/requestHander'

export default function ForgotPassword() {
	const [email, setEmail] = useState('')
	const sendResetPassword = async () => {
		if (!email) {
			setAlert({ type: 'danger', message: 'Ingresa tu dirección de e-mail' })
			return
		}
		setLoading(true)
		const result = await requestHandler('forgot_password', { email })
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				type: 'danger',
				message: result.data
			})
			return
		}
		console.log(result)
	}

	return (
		<div className="auth-container">
			<div className="form">
				<div className="container-logos">
					<img src={require('assets/img/logo.png')} alt="Logo" />
					<img src={require('assets/img/logo-cornare.png')} alt="Logo" />
				</div>
				<div className="container-text">
					<h1>Recuperar contraseña</h1>
					<p>Ingresa tu dirección de e-mail para recuperar tu contraseña</p>
				</div>
				<Input
					type="email"
					name="email"
					label="E-mail"
					placeholder="Ingresa tu e-mail"
					onChange={e => setEmail(e.target.value)}
					value={email}
					required
				/>
				<Btn title="Recuperar contraseña" action={() => sendResetPassword()} />
				<Link title="Volver" to={'/'} />
			</div>
		</div>
	)
}
