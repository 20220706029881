import { useEffect, useState } from 'react'
import WidgetResultsAqueduct from 'components/Dashboard/WidgetResultsAqueduct'
import QuestionaryDetailsResults from 'components/Questions/QuestionaryDetailsResults'
import Answer from 'components/Questions/Answer'
import Btn from 'components/Btn'
import Row from 'components/Row'
import Title from 'components/Title'
import { useParams } from 'react-router-dom'
import { setLoading, setAlert } from 'store'
import { requestHandler } from 'utils/requestHander'
import { percentages, getPercentageOf } from 'utils/mixins'
import { downloadExcel } from 'react-export-table-to-excel'

export default function Results() {
	const { id } = useParams()
	const [aqueduct, setAqueduct] = useState({})
	const [dataWidgetResults, setDataWidgetResults] = useState({})
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [version, setVersion] = useState('')
	const [answerDate, setAnswerDate] = useState('')
	const [answersEnvironment, setAnswersEnvironment] = useState([])
	const [answersSocial, setAnswersSocial] = useState([])
	const [answersEconomic, setAnswersEconomic] = useState([])
	const [pathId, setPathId] = useState('')

	const getData = async () => {
		setLoading(true)
		const result = await requestHandler('get_results', { id })
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.data, type: 'danger' })
			return
		}
		setAqueduct(result.data.aqueduct)
		const lastAnswers = result.data.result
		setDataWidgetResults({
			environmental: getPercentageOf(
				lastAnswers.total_environmental,
				percentages.environmental,
				lastAnswers.environmental
			),
			social: getPercentageOf(lastAnswers.total_social, percentages.social, lastAnswers.social),
			economic: getPercentageOf(lastAnswers.total_economic, percentages.economic, lastAnswers.economic),
			points: {
				environmental: lastAnswers.total_environmental,
				social: lastAnswers.total_social,
				economic: lastAnswers.total_economic,
				total: lastAnswers.total_points
			}
		})
		setStartDate(lastAnswers.questionary_start_at)
		setEndDate(lastAnswers.questionary_end_at)
		setVersion(lastAnswers.questionary_version)
		setAnswerDate(lastAnswers.created_at)
		const answers = result.data.answers
		const environmental = answers.environmental.sort((a, b) => Number(a.position) - Number(b.position))
		const social = answers.social.sort((a, b) => Number(a.position) - Number(b.position))
		const economic = answers.economic.sort((a, b) => Number(a.position) - Number(b.position))
		setAnswersEnvironment(environmental)
		setAnswersSocial(social)
		setAnswersEconomic(economic)
		setPathId(lastAnswers.path_id)
	}

	const downloadXlsx = () => {
		// Armamos los encabezados del excel
		const header = ['Versión']
		for (const answerEnv of answersEnvironment) {
			header.push('Ambiental: ' + answerEnv.question)
		}
		header.push('Total puntos Ambiental')
		for (const answerSoc of answersSocial) {
			header.push('Social: ' + answerSoc.question)
		}
		header.push('Total puntos Social')
		for (const answerEco of answersEconomic) {
			header.push('Económico: ' + answerEco.question)
		}
		header.push('Total puntos Económico')
		header.push('Total puntos')
		header.push('Porcentaje Ambiental')
		header.push('Porcentaje Social')
		header.push('Porcentaje Económico')
		header.push('Porcentaje Total')
		// Armamos el cuerpo del excel
		const body = [[version]]
		let pointsEnviorment = 0
		let pointsSocial = 0
		let pointsEconomic = 0
		let pointsTotal = 0
		for (const answerEnv of answersEnvironment) {
			body[0].push(answerEnv.answer.label)
			pointsEnviorment += answerEnv.answer.points
			pointsTotal += answerEnv.answer.points
		}
		body[0].push(pointsEnviorment)
		for (const answerSoc of answersSocial) {
			body[0].push(answerSoc.answer.label)
			pointsSocial += answerSoc.answer.points
			pointsTotal += answerSoc.answer.points
		}
		body[0].push(pointsSocial)
		for (const answerEco of answersEconomic) {
			body[0].push(answerEco.answer.label)
			pointsEconomic += answerEco.answer.points
			pointsTotal += answerEco.answer.points
		}
		body[0].push(pointsEconomic)
		body[0].push(pointsTotal)

		body[0].push(dataWidgetResults.environmental + '%')
		body[0].push(dataWidgetResults.social + '%')
		body[0].push(dataWidgetResults.economic + '%')
		const totalPercentage = dataWidgetResults.environmental + dataWidgetResults.social + dataWidgetResults.economic
		body[0].push(totalPercentage + '%')
		setAlert({ message: 'Descargando archivo', type: 'info' })
		const aqueductName = aqueduct.name.replaceAll(' ', '_')
		const fileName = 'Resultados_' + aqueductName + '_version_' + version
		downloadExcel({ fileName, sheet: 'Resultados', tablePayload: { header, body } })
	}

	useEffect(() => {
		getData()
	}, [id])

	return (
		<>
			<Title text="Resultados" back />
			<Row style={{ marginBottom: 20 }}>
				<Btn title="Imprimir PDF" action={() => window.print()} style={{ width: 200 }} />
				<Btn title="Descargar XLSX" action={() => downloadXlsx()} style={{ width: 200 }} />
			</Row>
			<QuestionaryDetailsResults
				startDate={startDate}
				endDate={endDate}
				version={version}
				answerDate={answerDate}
				aqueduct={aqueduct}
			/>
			<WidgetResultsAqueduct data={dataWidgetResults} />
			<div className="container-questions">
				<div className="title-icon-dimention">
					<img src={require('assets/img/enviorment.png')} alt="Ambiental" />
					<h2>Dimensión Ambiental</h2>
				</div>
				{answersEnvironment.map(answer => (
					<Answer key={answer.id} category="environmental" data={answer} idAqueduct={aqueduct.id} pathId={pathId} />
				))}
				<div className="title-icon-dimention">
					<img src={require('assets/img/social.png')} alt="Social" />
					<h2>Dimensión Social</h2>
				</div>
				{answersSocial.map(answer => (
					<Answer key={answer.id} category="social" data={answer} idAqueduct={aqueduct.id} pathId={pathId} />
				))}
				<div className="title-icon-dimention">
					<img src={require('assets/img/economic.png')} alt="Economico" />
					<h2>Dimensión Económica</h2>
				</div>
				{answersEconomic.map(answer => (
					<Answer key={answer.id} category="economic" data={answer} idAqueduct={aqueduct.id} pathId={pathId} />
				))}
			</div>
		</>
	)
}
