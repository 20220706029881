import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { setAlert } from 'store'

export default function Alert() {
	const alert = useSelector(state => state.globalFlags.alert)
	const [isVisible, setIsVisible] = useState(null) // Estado que controla visibilidad del alert, para controlar animaciones
	const [timerControl, setTimerControl] = useState(null) // Estado que controla el tiempo de la animación
	const hanleClick = e => {
		// Si le dan click a la x de la alert
		e.preventDefault()
		hideAlert()
	}
	// Se agrega Time Out para darle una espera a que la animación termine
	const hideAlert = () => {
		setIsVisible(false)
		setTimeout(() => {
			setAlert(null)
		}, 400)
	}
	// El alert desaparece después de 4000ms por defecto
	const timeOut = (time = 4000) => setTimerControl(setTimeout(hideAlert, time))

	useEffect(() => {
		/*
		 cada vez que se muestre el alert reiniciamos el interval que oculta el alert,
		 con esto nos aseguramos de que si alguien le da click muchas veces a un botón
		 que hace una validación y muestra el alert evitamo el bug de renderizacion del alert
		*/
		if (alert) {
			if (timerControl) {
				// borramos el contador que oculta el alert
				clearInterval(timerControl)
				setTimerControl(null)
			}
			setIsVisible(true) // asigna la clase css para la animación
			timeOut(alert.time || 4000) // iniciamos el contador que oculta el alert (por defecto esta en 4 segundos, a futuro la idea seria poder pasarle un parametro para que se demore más en ocultarse)
		}
	}, [alert])

	// Cambia clase de CSS según el tipo de alert => alert.type > posibles types: 'danger', 'warning', 'success', 'info'
	const getAlertClass = alertType => {
		switch (alertType) {
			case 'info':
				return 'alert-info'
			case 'success':
				return 'alert-success'
			case 'warning':
				return 'alert-warning'
			case 'danger':
				return 'alert-danger'
			default:
				return 'alert-info'
		}
	}

	if (alert) {
		return (
			<div className={`alert-container ${isVisible ? 'alertIn' : ''} ${getAlertClass(alert.type)}`}>
				<section>
					<h3>{alert.title}</h3>
					<p>{alert.message}</p>
				</section>
				<a href="#" onClick={hanleClick}>
					<svg width="20px" height="20px" viewBox="0 0 512 512">
						<polygon points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313" />
					</svg>
				</a>
			</div>
		)
	}
}
