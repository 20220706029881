import Btn from 'components/Btn'
import Row from 'components/Row'
export default function Confirm({ data = null, onConfirm, onClose }) {
	if (data) {
		return (
			<div className="modal">
				<div className="container-confirm">
					{data.title && <h2>{data.title}</h2>}
					<p>{data.message}</p>
					<Row style={{ width: '100%', marginTop: 25 }}>
						<Btn action={() => onClose()} style={{ marginRight: 70 }} styleType="secondary" title={'Cancelar'} />
						<Btn action={() => onConfirm()} title={'Confirmar'} />
					</Row>
				</div>
			</div>
		)
	}
}
