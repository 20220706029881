import { useEffect, useState } from 'react'
import { requestHandler } from 'utils/requestHander'
import Card from 'components/Card'
import Table from 'components/Table'
import { setLoading, setAlert } from 'store'
import { useNavigate } from 'react-router-dom'

export default function Index() {
	const navigate = useNavigate()
	const [aqueducts, setAqueducts] = useState([])
	const columns = [
		{ header: 'ID', accessorKey: 'id' },
		{ header: 'Nombre', accessorKey: 'name' },
		{ header: 'Subregión', accessorKey: 'subregion' },
		{ header: 'Subs comerciales', accessorKey: 'subCommertial' },
		{ header: 'Subs Residenciales', accessorKey: 'subResidential' },
		{ header: 'Representante legal', accessorKey: 'legalRepresentative' },
		{ header: 'Fecha de registro', accessorKey: 'created_at' },
		{
			header: 'Acciones',
			actions: {
				show: data => navigate('/aqueducts/show/' + data.id)
			}
		}
	]
	const getData = async () => {
		setLoading(true)
		const result = await requestHandler('get_aqueducts', {})
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				type: 'danger',
				message: result.data
			})
			return
		}
		setAqueducts(result.data)
	}

	useEffect(() => {
		getData()
	}, [])

	return (
		<Card title="Acueductos registrados">
			<br />
			<Table columns={columns} data={aqueducts} />
		</Card>
	)
}
