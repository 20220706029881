function Btn({
	title,
	smallText,
	action = null,
	styleType = 'primary',
	type = 'button',
	style = {},
	disabled = false
}) {
	return (
		<button
			style={style}
			className={styleType}
			disabled={disabled}
			type={type}
			onClick={() => {
				if (action) {
					action()
				}
			}}>
			{title}
			{smallText && <small>{smallText}</small>}
		</button>
	)
}
export default Btn
