import { useState, useEffect } from 'react'
import Card from 'components/Card'
import Input from 'components/Input'
import { setAlert } from 'store'
export default function Question({ question, defaultAnswer, onAnswer, category, onDeleteFile }) {
	const [options, setOptions] = useState([])
	const [selected, setSelected] = useState(null)
	const [showInputFiles, setShowInputFiles] = useState(false)
	const [files, setFiles] = useState([])
	const extensionsPermited = ['pdf', 'xls', 'xlsx', 'doc', 'docx', 'png', 'jpg']
	const filesIcons = {
		pdf: 'pdf',
		xls: 'xls',
		xlsx: 'xls',
		docx: 'doc',
		png: 'img',
		jpg: 'img',
		jpeg: 'img'
	}
	const getIconByExtension = file => {
		const extension = file.split('.').pop().toLowerCase()
		return filesIcons[extension]
	}
	const onSelected = val => {
		setSelected(val)
		if (val === null) {
			setShowInputFiles(false)
			setFiles([])
			onAnswer({
				position: question.position,
				answer: null
			})
			return
		}
		if (val.filesEnabled === false) {
			setShowInputFiles(false)
			setFiles([])
			onAnswer({
				position: question.position,
				answer: {
					id: val.id,
					label: val.label,
					points: val.points,
					files: []
				}
			})
			return
		}
		setShowInputFiles(true)
		onAnswer({
			position: question.position,
			answer: null
		})
	}
	const onSelecFiles = val => {
		const filesInput = val.target.files
		const finalFiles = []
		// Validamos las extensiones y los tamaños de los archivos
		for (const file of filesInput) {
			const extension = file.name.split('.').pop().toLowerCase()
			if (!extensionsPermited.includes(extension)) {
				setAlert({ message: `El formato del archivo ${file.name} no es permitido`, type: 'danger' })
				return
			}
			// 2MB
			if (file.size > 2097152) {
				setAlert({ message: `El archivo ${file.name} excede el tamaño permitido ( 2 MB )`, type: 'danger', time: 7000 })
				return
			}
			let icon = ''
			switch (extension) {
				case 'pdf':
					icon = 'pdf'
					break
				case 'xls':
				case 'xlsx':
					icon = 'xls'
					break
				case 'doc':
				case 'docx':
					icon = 'doc'
					break
				case 'png':
				case 'jpg':
					icon = 'img'
					break
			}
			finalFiles.push({
				icon,
				file
			})
		}
		document.getElementById(question.id + '_files').value = ''
		const filesToSet = [...finalFiles, ...files]
		setFiles(filesToSet)
		onAnswer({
			position: question.position,
			answer: {
				id: selected.id,
				label: selected.label,
				points: selected.points,
				files: filesToSet
			}
		})
	}
	const deleteFile = (e, index) => {
		e.preventDefault()
		const fileToDelete = files[index]
		const newFiles = files.filter((file, i) => i !== index)
		if (typeof fileToDelete === 'string') {
			// esto es para indicarle al back que un archivo se va a eliminar
			onDeleteFile(fileToDelete, question.position)
		}
		if (newFiles.length === 0) {
			onAnswer({
				position: question.position,
				answer: null
			})
		}
		setFiles(newFiles)
	}

	useEffect(() => {
		const options = JSON.parse(question.options)
		setOptions(options)
		if (defaultAnswer) {
			const option = options.find(o => o.id === defaultAnswer.id)
			setSelected(option)
			if (option.filesEnabled) {
				setShowInputFiles(true)
			}
			if (defaultAnswer.files.length > 0) {
				setFiles(defaultAnswer.files)
			}
		} else {
			setSelected(null)
			setShowInputFiles(false)
			setFiles([])
		}
	}, [question, defaultAnswer])

	return (
		<Card>
			<h2 className={`title-question`}>
				<span className={`circle ${category}`}>{question.position}</span>
				<span className="title">{question.question}</span>
			</h2>
			<div className="container-option">
				<Input
					type="select"
					name={question.id}
					options={options}
					placeholder="Selecciona una opción"
					value={selected}
					onChange={onSelected}
				/>
				{showInputFiles && (
					<>
						{files.length > 0 && (
							<div className="container-files">
								{files.map((file, index) => (
									<div key={index} className="file">
										<a href="#" className="delete-file" onClick={e => deleteFile(e, index)}>
											<img src={require('assets/img/trash.png')} alt="Basura" />
										</a>
										{typeof file === 'string' ? (
											<>
												<img src={require(`assets/img/${getIconByExtension(file)}.png`)} alt="Archivo" />
												<span>{file}</span>
											</>
										) : (
											<>
												<img src={require(`assets/img/${file.icon}.png`)} alt="Archivo" />
												<span>{file.file.name}</span>
											</>
										)}
									</div>
								))}
							</div>
						)}
						<Input
							label={selected.filesLabel || 'Por favor cargue los archivos correspondientes'}
							subLabel={`Formatos permitidos: pdf, xls, xlsx, doc, docx, png, jpg`}
							type="file"
							multiple
							required
							id={question.id + '_files'}
							name={question.id + '_files'}
							extensions={extensionsPermited}
							onChange={onSelecFiles}
						/>
					</>
				)}
			</div>
		</Card>
	)
}
