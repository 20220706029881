import Card from 'components/Card'
import { dateParse } from 'utils/mixins'
export default function QuestionaryDetailsResults({ startDate, endDate, version, aqueduct }) {
	return (
		<Card title="Detalles del acueducto">
			<strong>Acueducto: {aqueduct.name}</strong>
			<br />
			<strong>Subregión: {aqueduct.subregion}</strong>
			<br />
			<strong>Suscriptores residenciales: {aqueduct.subResidential}</strong>
			<br />
			<strong>Suscriptores comerciales: {aqueduct.subCommertial}</strong>
			<br />
			<strong>Representante legal: {aqueduct.legalRepresentative}</strong>
			<br />
			<br />
			<h2>Detalles de cuestionario</h2>
			<strong>
				Fechas del cuestionario: {dateParse(startDate)} - {dateParse(endDate)}
			</strong>
			<br />
			<strong>Versión del cuestionario: {version}</strong>
		</Card>
	)
}
