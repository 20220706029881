import Row from 'components/Row'
import Link from 'components/Link'
export default function Card({ title = null, action = null, children, style = {}, styleType }) {
	return (
		<div className={`card ${styleType ?? ''}`} style={style}>
			{(title !== null || action !== null) && (
				<Row>
					{title && <h1>{title}</h1>}
					{action && <Link action={action.action} title={action.title} />}
				</Row>
			)}
			{children}
		</div>
	)
}
