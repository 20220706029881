import { useState, useEffect } from 'react'
import Card from 'components/Card'
import Row from 'components/Row'
import Input from 'components/Input'
import Btn from 'components/Btn'
import { useSelector } from 'react-redux'
import { setLoading, setAlert, setUser } from 'store'
import { requestHandler } from 'utils/requestHander'
export default function CreateUpdateAqueduct() {
	const aqueduct = useSelector(state => state.user.aqueduct)
	const [name, setName] = useState('')
	const [subregion, setSubregion] = useState(null)
	const [selectedMunicipalities, setSelectedMunicipalities] = useState([])
	const [selectedSidewalks, setSelectedSidewalks] = useState([])
	const [subResidential, setSubResidential] = useState('')
	const [subCommertial, setSubCommertial] = useState('')
	const [legalRepresentative, setLegalRepresentative] = useState('')
	const [phoneRepresentative, setPhoneRepresentative] = useState('')
	// -------------
	const [subregions, setSubregions] = useState([])
	const [municipalities, setMunicipalities] = useState([])
	const [sidewalks, setSidewalks] = useState([])

	const sendData = async () => {
		if (name === '' || name.length < 3) {
			setAlert({
				message: 'Ingresa el nombre del acueducto',
				type: 'danger'
			})
			return
		}
		if (subregion === null) {
			setAlert({
				message: 'Selecciona la subregión',
				type: 'danger'
			})
			return
		}
		if (selectedMunicipalities.length === 0) {
			setAlert({
				message: 'Selecciona almenos un municipio',
				type: 'danger'
			})
			return
		}
		if (sidewalks.length === 0) {
			setAlert({
				message: 'Ingresa la veredas atendidas',
				type: 'danger'
			})
			return
		}
		if (subResidential === null || subResidential === '') {
			setAlert({
				message: 'Ingresa los suscriptores residenciales',
				type: 'danger'
			})
			return
		}
		if (subCommertial === null || subCommertial === '') {
			setAlert({
				message: 'Ingresa los suscriptores industriales y comerciales',
				type: 'danger'
			})
			return
		}
		setLoading(true)
		const dataToSend = {
			name,
			subregion: subregion.label,
			municipalities: selectedMunicipalities,
			sidewalks: selectedSidewalks,
			subResidential,
			subCommertial,
			legalRepresentative,
			phoneRepresentative
		}
		if (aqueduct !== null) {
			dataToSend.id = aqueduct.id
		}
		const result = await requestHandler('create_update_aqueduct', dataToSend)
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				message: result.data,
				type: 'danger'
			})
			return
		}
		setUser(result.data)
		setAlert({
			message: 'Información del acueducto guardada correctamente',
			type: 'success'
		})
	}
	const getSubregions = async () => {
		setLoading(true)
		const result = await requestHandler('get_subregions', {})
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				message: result.data,
				type: 'danger'
			})
			return
		}
		const subr = result.data.map((s, i) => ({
			id: i,
			label: s
		}))
		if (aqueduct) {
			const subregionSelected = subr.find(s => s.label === aqueduct.subregion)
			if (subregionSelected) {
				setSubregion(subregionSelected)
				await getMunicipalities(subregionSelected.label, aqueduct.municipalities)
			}
		}
		setSubregions(subr)
	}
	const getMunicipalities = async (subregion, preselected) => {
		if (subregion) {
			setLoading(true)
			const result = await requestHandler('get_municipalities', { subregion })
			setLoading(false)
			if (result.status !== true) {
				setAlert({
					message: result.data,
					type: 'danger'
				})
				return
			}
			const muni = result.data.map((s, i) => ({
				id: i,
				label: s,
				value: s
			}))
			if (preselected) {
				const values = preselected.map(v => v.value)
				const municipalitiesSelected = muni.filter(m => values.includes(m.label))
				addMunicipality(subregion, municipalitiesSelected)
			} else {
				setSelectedMunicipalities([])
			}
			setMunicipalities(muni)
		} else {
			setMunicipalities([])
			setSelectedMunicipalities([])
		}
	}
	const addMunicipality = (subregion, val) => {
		const values = val.map(v => v.value)
		setSelectedMunicipalities(val)
		getSideWalks(subregion, values)
	}
	const getSideWalks = async (subregion, municipalities) => {
		setLoading(true)
		const result = await requestHandler('get_sidewalks', { subregion, municipalities })
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				message: result.data,
				type: 'danger'
			})
			return
		}
		const sidewalks = []
		for (const key in result.data) {
			for (const id in result.data[key]) {
				sidewalks.push({
					id,
					municipality: key,
					label: key + ': ' + result.data[key][id],
					value: key + ': ' + result.data[key][id]
				})
			}
		}
		setSidewalks(sidewalks)
	}
	const init = async () => {
		await getSubregions()
		if (aqueduct) {
			setName(aqueduct.name)
			setSelectedSidewalks(aqueduct.sidewalks)
			setSubResidential(aqueduct.subResidential)
			setSubCommertial(aqueduct.subCommertial)
			setLegalRepresentative(aqueduct.legalRepresentative)
			setPhoneRepresentative(aqueduct.phoneRepresentative)
		} else {
			setName('')
			setSubregion(null)
			setSelectedMunicipalities([])
			setSelectedSidewalks([])
			setSubResidential('')
			setSubCommertial('')
			setLegalRepresentative('')
			setPhoneRepresentative('')
		}
	}
	useEffect(() => {
		init()
	}, [aqueduct])

	return (
		<Card title={aqueduct ? 'Actualizar acueducto' : 'Registrar acueducto'}>
			<Row>
				<Input
					type="text"
					name="name"
					label="Nombre del acueducto"
					placeholder="Ingresa el nombre del acueducto"
					onChange={e => setName(e.target.value)}
					value={name}
					style={{ marginRight: 25 }}
					required
				/>
				<Input
					type="select"
					name="subregion"
					label="Subregión"
					options={subregions}
					placeholder="Selecciona la subregión"
					onChange={val => {
						setSubregion(val)
						getMunicipalities(val.label)
					}}
					value={subregion}
					required
				/>
			</Row>
			<Row>
				<Input
					type="select"
					name="selectedMunicipalities"
					label="Muicipios"
					placeholder="Selecciona los municipios"
					multi={true}
					options={municipalities}
					onChange={val => addMunicipality(subregion.label, val)}
					value={selectedMunicipalities}
					style={{ marginRight: 25 }}
					required
				/>
				<Input
					type="select"
					name="selectedSidewalks"
					label="Veredas"
					placeholder="Selecciona las veredas"
					multi={true}
					options={sidewalks}
					onChange={val => setSelectedSidewalks(val)}
					value={selectedSidewalks}
					required
				/>
			</Row>
			<Row>
				<Input
					type="number"
					name="subResidential"
					label="Suscriptores residenciales"
					placeholder="0"
					onChange={e => setSubResidential(e.target.value)}
					value={subResidential}
					style={{ marginRight: 25 }}
					required
				/>
				<Input
					type="number"
					name="subCommertial"
					label="Suscriptores industriales y comerciales"
					placeholder="0"
					onChange={e => setSubCommertial(e.target.value)}
					value={subCommertial}
					required
				/>
			</Row>
			<Row>
				<Input
					type="text"
					name="legalRepresentative"
					label="Nombre del representante legal"
					placeholder="Ingresa el nombre del representante legar"
					onChange={e => setLegalRepresentative(e.target.value)}
					value={legalRepresentative}
					style={{ marginRight: 25 }}
				/>
				<Input
					type="number"
					name="phoneRepresentative"
					label="Teléfono del representante legal"
					placeholder="Ingresa el teléfono del representante legar"
					onChange={e => setPhoneRepresentative(e.target.value)}
					value={phoneRepresentative}
				/>
			</Row>
			<Btn title={aqueduct ? 'Actualizar' : 'Registrar'} action={() => sendData()} style={{ width: 200 }} />
		</Card>
	)
}
