export default function Status({ status, small }) {
	const className = {
		active: 'green',
		inactive: 'gray',
		true: 'green',
		false: 'gray'
	}
	const labels = {
		active: 'Activo',
		inactive: 'Inactivo',
		true: 'Si',
		false: 'No'
	}
	return (
		<span className={`span-status ${className[String(status)]} ${small ? 'small' : ''}`}>{labels[String(status)]}</span>
	)
}
