import Row from 'components/Row'
import Card from 'components/Card'
import { getPercentageOf, percentages } from 'utils/mixins'

export default function WidgetResultsAqueduct({ data, showCongratulations }) {
	const isIdeal =
		String(data.environmental) === String(percentages.environmental) &&
		String(data.social) === String(percentages.social) &&
		String(data.economic) === String(percentages.economic)
	return (
		<div className="widgets-results">
			{isIdeal && showCongratulations && (
				<Card title="¡Felicidades!" styleType="success">
					<strong>Has alcanzado el puntaje ideal en los tres aspectos, ¡Buen trabajo!</strong>
				</Card>
			)}
			<Row>
				<Card>
					<img src={require('assets/img/enviorment.png')} alt="Ambiental" />
					<h1>Ambiental</h1>
					<span>Puntos: {data.points?.environmental}</span>
					<p>
						{data.environmental}% de {percentages.environmental}%
					</p>
					<div className="progress-bar-container">
						<div
							className="progress"
							style={{ width: `${getPercentageOf(data.environmental, 100, percentages.environmental)}%` }}></div>
					</div>
				</Card>
				<Card>
					<img src={require('assets/img/social.png')} alt="Social" />
					<h1>Social</h1>
					<span>Puntos: {data.points?.social}</span>
					<p>
						{data.social}% de {percentages.social}%
					</p>
					<div className="progress-bar-container">
						<div
							className="progress"
							style={{ width: `${getPercentageOf(data.social, 100, percentages.social)}%` }}></div>
					</div>
				</Card>
				<Card>
					<img src={require('assets/img/economic.png')} alt="Economico" />
					<h1>Económico</h1>
					<span>Puntos: {data.points?.economic}</span>
					<p>
						{data.economic}% de {percentages.economic}%
					</p>
					<div className="progress-bar-container">
						<div
							className="progress"
							style={{ width: `${getPercentageOf(data.economic, 100, percentages.economic)}%` }}></div>
					</div>
				</Card>
				<Card>
					<div className="img-alls">
						<img src={require('assets/img/enviorment.png')} alt="Ambiental" />
						<img src={require('assets/img/social.png')} alt="Social" />
						<img src={require('assets/img/economic.png')} alt="Economico" />
					</div>
					<h1>Total</h1>
					<span>Puntos: {data.points?.total}</span>
					<p>{data.environmental + data.social + data.economic}%</p>
					<div className="progress-bar-container">
						<div className="progress" style={{ width: `${data.environmental + data.social + data.economic}%` }}></div>
					</div>
				</Card>
			</Row>
		</div>
	)
}
