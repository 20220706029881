import { useEffect } from 'react'
import AppRoutes from 'Routes'
import Alert from 'components/Alert'
import Loader from 'components/Loader'
import { setLoading, setUser } from 'store'
import { requestHandler } from 'utils/requestHander'

function App() {
	const checkAuth = async () => {
		if (localStorage.avesS) {
			setLoading(true)
			const result = await requestHandler('check_auth', { session: localStorage.avesS })
			setLoading(false)
			if (result.status === true) {
				setUser(result.data)
			}
		}
	}

	useEffect(() => {
		checkAuth()
	}, [])

	return (
		<div className="App">
			<Alert />
			<Loader />
			<AppRoutes />
		</div>
	)
}

export default App
