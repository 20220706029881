import { useEffect, useState } from 'react'
import Card from 'components/Card'
import Table from 'components/Table'
import { requestHandler } from 'utils/requestHander'
import { setAlert, setLoading } from 'store'
import { useParams } from 'react-router-dom'
import { percentages, getPercentageOf } from 'utils/mixins'
import Title from 'components/Title'

export default function TopAqueducts() {
	const { version } = useParams()
	const [data, setData] = useState([])
	const columns = [
		{
			header: 'Posición',
			accessorKey: 'position',
			cell: ({ row }) => <span className={`position-table p${row.original.position}`}>{row.original.position}</span>
		},
		{ header: 'Acueducto', accessorKey: 'aqueduct_name' },
		{ header: 'Subregión', accessorKey: 'subregion' },
		{
			header: 'Ambiental',
			accessorKey: 'percent_enviormental',
			cell: ({ row }) => `${row.original.percent_enviormental}%`
		},
		{ header: 'Social', accessorKey: 'percent_social', cell: ({ row }) => `${row.original.percent_social}%` },
		{ header: 'Económico', accessorKey: 'percent_economic', cell: ({ row }) => `${row.original.percent_economic}%` },
		{ header: 'Total', accessorKey: 'percent_total', cell: ({ row }) => `${row.original.percent_total}%` }
	]
	const getData = async () => {
		setLoading(true)
		const result = await requestHandler('get_top_aqueducts', { version })
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.data, type: 'danger' })
			return
		}
		const topResult = result.data.map(item => {
			item.percent_enviormental = getPercentageOf(
				item.total_environmental,
				percentages.environmental,
				item.environmental
			)
			item.percent_social = getPercentageOf(item.total_social, percentages.social, item.social)
			item.percent_economic = getPercentageOf(item.total_economic, percentages.economic, item.economic)
			item.percent_total = getPercentageOf(item.total_points, 100, item.points)
			return item
		})
		setData(topResult)
	}
	useEffect(() => {
		getData()
	}, [])

	return (
		<>
			<Title text="Top de acueductos" back />
			<Card>
				<Table columns={columns} data={data} filters={false} />
			</Card>
		</>
	)
}
