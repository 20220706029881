import { useState } from 'react'
import Select from 'react-select'

function Input(props) {
	const {
		type,
		label = null,
		subLabel,
		name,
		placeholder = '',
		value,
		required = false,
		disabled = false,
		onChange,
		style,
		multi,
		options,
		onEnter,
		onBlur,
		extensions,
		multiple,
		id
	} = props
	const [showPassword, setShowPassword] = useState(false)
	const extensionsPermited = {
		pdf: 'application/pdf',
		xls: 'application/vnd.ms-excel',
		xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		doc: 'application/msword',
		docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		png: 'image/png',
		jpg: 'image/jpeg'
	}
	switch (type) {
		case 'switch':
			return (
				<label className="switch" style={style}>
					<input type="checkbox" checked={value} name={name} onChange={onChange} disabled={disabled} />
					<span className="slider"></span>
					{label ?? label}
				</label>
			)
		case 'password':
			return (
				<div className="form-control" style={style}>
					<input
						label={label}
						name={name}
						placeholder={placeholder}
						defaultValue={value}
						required={required ? 'required' : ''}
						type={showPassword ? 'text' : type}
						onChange={onChange}
					/>
					{label && (
						<label>
							{label}
							{required ? ' *' : ''}
						</label>
					)}
					<img
						className="show-no-show"
						style={{ opacity: showPassword ? 0.5 : 1 }}
						src={require(`assets/img/show.png`)}
						alt="Show/Hide password"
						onClick={() => setShowPassword(!showPassword)}
					/>
				</div>
			)
		case 'textarea':
			return (
				<div className="form-control" style={style}>
					<textarea
						name={name}
						placeholder={placeholder}
						defaultValue={value}
						disabled={disabled}
						rows={5}
						required={required ? 'required' : ''}
						onChange={onChange}
					/>
					{label && (
						<label>
							{label}
							{required ? ' *' : ''}
						</label>
					)}
				</div>
			)
		case 'select':
			return (
				<div className="form-control" style={style}>
					<div className={`select__control ${disabled ? 'disabled' : ''}`}>
						<Select
							styles={{
								control: (baseStyles, state) => ({
									...baseStyles,
									borderColor: state.isFocused ? 'grey' : 'red'
								})
							}}
							classNamePrefix="select_input"
							value={value}
							isDisabled={disabled}
							isClearable={true}
							onChange={onChange}
							isSearchable={true}
							isMulti={multi}
							name={name}
							options={options}
							placeholder={placeholder || 'Seleccionar...'}
						/>
					</div>
					{label && (
						<label>
							{label}
							{required ? ' *' : ''}
						</label>
					)}
				</div>
			)
		case 'file': {
			const accept = extensions
				.map(ext => {
					return extensionsPermited[ext]
				})
				.join(',')
			return (
				<div className="form-control" style={style}>
					<input
						type={type}
						name={name}
						placeholder={placeholder}
						disabled={disabled}
						accept={accept}
						id={id}
						required={required ? 'required' : ''}
						multiple={multiple}
						onChange={onChange}
					/>
					{label && (
						<label>
							{label}
							{required ? ' *' : ''}
						</label>
					)}
					{subLabel && <small>{subLabel}</small>}
				</div>
			)
		}
		default:
			return (
				<div className="form-control" style={style}>
					<input
						type={type}
						name={name}
						placeholder={placeholder}
						value={value}
						disabled={disabled}
						required={required ? 'required' : ''}
						onChange={onChange}
						onBlur={onBlur}
						onKeyDown={e => {
							if (e.key === 'Enter' && onEnter) {
								e.preventDefault()
								onEnter(e)
							}
						}}
					/>
					{label && (
						<label>
							{label}
							{required ? ' *' : ''}
						</label>
					)}
					{subLabel && <small>{subLabel}</small>}
				</div>
			)
	}
}
export default Input
