import 'assets/css/styles.scss'
// import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from 'store'
import App from 'App'
import { GoogleOAuthProvider } from '@react-oauth/google'
import googleCredentials from 'credentials/google'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Provider store={store}>
		<GoogleOAuthProvider clientId={googleCredentials.idClient}>
			<App />
		</GoogleOAuthProvider>
	</Provider>
)
