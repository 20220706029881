import { createSlice } from '@reduxjs/toolkit'
export const globalFlagsSlice = createSlice({
	name: 'globalFlags',
	initialState: {
		alert: null,
		confirm: null,
		loading: false,
		modals: {}
	},
	reducers: {
		setAlert: (state, action) => {
			state.alert = action.payload
			return state
		},
		setConfirm: (state, action) => {
			state.confirm = action.payload
			return state
		},
		setLoading: (state, action) => {
			state.loading = action.payload
			return state
		},
		setModal: (state, action) => {
			state.modals[action.payload.id] = action.payload.status
			return state
		}
	}
})
export default globalFlagsSlice
