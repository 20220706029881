import { useEffect, useState } from 'react'
import Tabs from 'components/Tabs'
import Card from 'components/Card'
import Status from 'components/Status'
import Row from 'components/Row'
import Btn from 'components/Btn'
import Table from 'components/Table'
import Reorder from 'components/Questions/Reorder'
import Confirm from 'components/Confirm'
import SettingsQuestionary from 'components/Questions/SettingsQuestionary'
import { dateParse } from 'utils/mixins'
import { setLoading, setAlert } from 'store'
import { requestHandler } from 'utils/requestHander'
import { useNavigate } from 'react-router-dom'
export default function Index() {
	const [showSettings, setShowSettings] = useState(false)
	const [settings, setSettings] = useState({ startDate: '', endDate: '', description: '', version: 0 })
	const [dataToOrder, setDataToOrder] = useState({ questions: [], title: '', category: '' })
	const [dataToDelete, setDataToDelete] = useState(null)
	const [questionsByCategory, setQuestionsByCategory] = useState({
		environmental: [],
		social: [],
		economic: []
	})
	const [pointsByCategory, setPointsByCategory] = useState({
		environmental: 0,
		social: 0,
		economic: 0
	})
	const categories = {
		environmental: 'Dimensión ambiental',
		social: 'Dimensión social',
		economic: 'Dimensión económica'
	}
	const columns = [
		{ header: 'Posición', accessorKey: 'position' },
		{ header: 'Pregunta', accessorKey: 'question' },
		{ header: 'Puntos', accessorKey: 'points' },
		{
			header: 'Acciones',
			actions: {
				edit: data => navigate(`/questions/edit/${data.category}/${data.id}`),
				delete: data =>
					setDataToDelete({
						title: 'Eliminar pregunta',
						message: `¿Realmente desea eliminar la pregunta: ${data.position}?`,
						data: {
							id: data.id,
							category: data.category
						}
					})
			}
		}
	]
	const navigate = useNavigate()
	const getData = async () => {
		setLoading(true)
		const result = await requestHandler('get_questonary', {})
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.data, type: 'danger' })
			return
		}
		const questions = result.data.questions
		// Ambiental
		const environmental = questions.filter(q => q.category === 'environmental').sort((a, b) => a.position - b.position)
		const environmentalPoints = environmental.reduce((a, q) => a + Number(q.points), 0)
		// Social
		const social = questions.filter(q => q.category === 'social').sort((a, b) => a.position - b.position)
		const socialPoints = social.reduce((a, q) => a + Number(q.points), 0)
		// Economico
		const economic = questions.filter(q => q.category === 'economic').sort((a, b) => a.position - b.position)
		const economicPoints = economic.reduce((a, q) => a + Number(q.points), 0)
		// Settings
		setSettings({
			startDate: result.data.settings.start_date,
			endDate: result.data.settings.end_date,
			description: result.data.settings.description,
			version: result.data.settings.version
		})
		setQuestionsByCategory({
			environmental,
			social,
			economic
		})
		setPointsByCategory({
			environmental: environmentalPoints,
			social: socialPoints,
			economic: economicPoints
		})
	}
	const deleteQuestion = async ({ id, category }) => {
		setLoading(true)
		const result = await requestHandler('delete_question', { id, category })
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.data, type: 'danger' })
			return
		}
		setAlert({ message: 'Pregunta eliminada correctamente', type: 'success' })
		setDataToDelete(null)
		getData()
	}
	useEffect(() => {
		getData()
	}, [])

	if (dataToOrder.questions.length > 0) {
		return (
			<Reorder
				questions={dataToOrder.questions}
				title={dataToOrder.title}
				onSuccess={() => {
					setDataToOrder({ questions: [], title: '', category: '' })
					getData()
				}}
				onCancel={() => setDataToOrder({ questions: [], title: '', category: '' })}
			/>
		)
	} else {
		return (
			<>
				<Confirm
					data={dataToDelete}
					onConfirm={() => deleteQuestion(dataToDelete.data)}
					onClose={() => setDataToDelete(null)}
				/>
				<SettingsQuestionary
					settings={settings}
					onClose={() => setShowSettings(false)}
					show={showSettings}
					onSuccess={setSettings}
				/>
				<Card title="Cuestionario">
					<Row>
						<div>
							<p>
								Versión: <b>{settings.version}</b>
							</p>
							<p>Fecha de inicio: {dateParse(settings.startDate)}</p>
							<p>Fecha de fin: {dateParse(settings.endDate)}</p>
							<p>
								Total puntos cuestionario:{' '}
								{pointsByCategory.environmental + pointsByCategory.social + pointsByCategory.economic}
							</p>
							<p>Descripción: {settings.description}</p>
							<p>
								Estado:{' '}
								<Status
									status={
										Date.now() >= Number(settings.startDate) && Date.now() <= Number(settings.endDate)
											? 'active'
											: 'inactive'
									}
								/>
							</p>
						</div>
						<Btn title="Configurar cuestionario" style={{ width: 320 }} action={() => setShowSettings(true)} />
					</Row>
					<br />
				</Card>
				<br />
				<Tabs
					tabs={[
						{
							label: categories.environmental,
							component: (
								<Table
									columns={columns}
									data={questionsByCategory.environmental}
									customComponentFilters={() => <strong>Total puntos: {pointsByCategory.environmental}</strong>}
									actions={[
										{
											title: 'Reordenar',
											action: () =>
												setDataToOrder({
													questions: questionsByCategory.environmental,
													title: categories.environmental
												})
										},
										{
											title: 'Crear',
											action: () => navigate('/questions/create/environmental')
										}
									]}
								/>
							)
						},
						{
							label: categories.social,
							component: (
								<Table
									columns={columns}
									data={questionsByCategory.social}
									customComponentFilters={() => <strong>Total puntos: {pointsByCategory.social}</strong>}
									actions={[
										{
											title: 'Reordenar',
											action: () => setDataToOrder({ questions: questionsByCategory.social, title: categories.social })
										},
										{
											title: 'Crear',
											action: () => navigate('/questions/create/social')
										}
									]}
								/>
							)
						},
						{
							label: categories.economic,
							component: (
								<Table
									columns={columns}
									data={questionsByCategory.economic}
									customComponentFilters={() => <strong>Total puntos: {pointsByCategory.economic}</strong>}
									actions={[
										{
											title: 'Reordenar',
											action: () =>
												setDataToOrder({ questions: questionsByCategory.economic, title: categories.economic })
										},
										{
											title: 'Crear',
											action: () => navigate('/questions/create/economic')
										}
									]}
								/>
							)
						}
					]}
				/>
			</>
		)
	}
}
