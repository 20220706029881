import Card from 'components/Card'
import Row from 'components/Row'
import Btn from 'components/Btn'
import { dateParse } from 'utils/mixins'
import { useNavigate } from 'react-router-dom'
export default function WidgetBeingQuestionary({ startDate, endDate, version }) {
	const navigate = useNavigate()
	return (
		<Card title="¡Cuestionario activo!">
			<p>Responde el cuestionario para evaluar el estado Ambiental, Solical y Económico de tu acueducto.</p>
			<strong>Fecha de inicio: {dateParse(startDate)}</strong>
			<br />
			<strong>Fecha de finalización: {dateParse(endDate)}</strong>
			<br />
			<strong>VERSIÓN: {version}</strong>
			<br />
			<br />
			<Row>
				<div className="icons">
					<img src={require('assets/img/enviorment.png')} alt="Ambiental" />
					<img src={require('assets/img/social.png')} alt="Social" />
					<img src={require('assets/img/economic.png')} alt="Economico" />
				</div>
				<Btn title="Responder cuestionario" style={{ width: 320 }} action={() => navigate('/questionary/blank')} />
			</Row>
		</Card>
	)
}
