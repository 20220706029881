import { createSlice } from '@reduxjs/toolkit'

const getInitState = () => {
	return {}
}

const userSlice = createSlice({
	name: 'user',
	initialState: getInitState(),
	reducers: {
		setUser: (state, action) => {
			state = action.payload
			if (action.payload.session) {
				localStorage.avesS = action.payload.session
			}
			return state
		},
		resetUser: () => {
			localStorage.avesS = null
			return getInitState()
		}
	}
})
export default userSlice
