export function dateParse(date) {
	date = Number(date)
	const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }
	return new Date(date).toLocaleDateString('es-ES', options)
}

export function millisecondsToDatetimeLocal(milliseconds) {
	const date = new Date(Number(milliseconds))
	const year = date.getFullYear()
	const month = String(date.getMonth() + 1).padStart(2, '0')
	const day = String(date.getDate()).padStart(2, '0')
	const hours = String(date.getHours()).padStart(2, '0')
	const minutes = String(date.getMinutes()).padStart(2, '0')
	const seconds = String(date.getSeconds()).padStart(2, '0')
	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}
export function datetimeLocalToMilliseconds(datetimeLocal) {
	const date = new Date(datetimeLocal)
	return date.getTime()
}

export const percentages = {
	environmental: 33,
	social: 33,
	economic: 33
}

export function getPercentageOf(x, y, z) {
	return Math.round((Number(x) * Number(y)) / Number(z))
}
