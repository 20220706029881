import { useState } from 'react'
import { Outlet, NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Home from 'views/Dashboard/Home'
import { setLoading, setAlert, resetUser } from 'store'
import { requestHandler } from 'utils/requestHander'
export default function Container() {
	const location = useLocation()
	const currentRoute = location.pathname
	const user = useSelector(state => state.user)
	const [openMenu, setOpenMenu] = useState(false)
	const navigate = useNavigate()
	const logout = async () => {
		setLoading(true)
		const result = await requestHandler('logout', {})
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				message: result.data,
				type: 'danger'
			})
			return
		}
		resetUser()
		navigate('/')
	}

	return (
		<div className="dashboard">
			<aside className="hide-print">
				<div className="container-logos">
					<img src={require('assets/img/logo.png')} alt="Logo" />
					<img src={require('assets/img/logo-cornare.png')} alt="Logo" />
				</div>
				<nav>
					<NavLink to="/home">Inicio</NavLink>
					{user.is_admin === 'Si' && (
						<>
							<NavLink to="/aqueducts">Acueductos</NavLink>
							<NavLink to="/questions">Cuestionario</NavLink>
						</>
					)}
				</nav>
			</aside>
			<section>
				<header className="hide-print">
					<h2>Inicio</h2>
					<label className="user-container" htmlFor="menu-user">
						<div>
							<b>{user.name}</b>
							<small>{user.email}</small>
						</div>
						<span>
							{user.avatar ? (
								<img className="avatar" src={user.avatar} alt="User" />
							) : (
								<img className="default" src={require('assets/img/user.png')} alt="User" />
							)}
						</span>
						<input type="checkbox" id="menu-user" checked={openMenu} onChange={() => setOpenMenu(!openMenu)} />
						<div className="user-menu">
							<NavLink to="/profile" onClick={() => setOpenMenu(false)}>
								Configuración de perfil
							</NavLink>
							{user.is_admin !== 'Si' && (
								<NavLink to="/aqueduct" onClick={() => setOpenMenu(false)}>
									Configuración de acueducto
								</NavLink>
							)}
							<a
								href="#"
								onClick={e => {
									e.preventDefault()
									logout()
								}}>
								Cerrar sesión
							</a>
						</div>
					</label>
				</header>
				<div className="content-container full-print">
					<Outlet />
					{currentRoute === '/' && <Home />}
				</div>
			</section>
		</div>
	)
}
