import { useEffect, useState } from 'react'
import WidgetStepsQuestions from 'components/Questions/WidgetStepsQuestions'
import Question from 'components/Questions/Question'
import Confirm from 'components/Confirm'
import Row from 'components/Row'
import Btn from 'components/Btn'
import { useNavigate, useParams } from 'react-router-dom'
import { setLoading, setAlert } from 'store'
import { requestHandler, requestHandlerFiles } from 'utils/requestHander'
export default function Questionary() {
	const navigate = useNavigate()
	const { id } = useParams()
	const [dataToSend, setDataToSend] = useState(null)
	const [version, setVersion] = useState(0)
	const [currentCategory, setCurrentCategory] = useState('environmental')
	const [positions, setPositions] = useState([])
	const [questionsByCategory, setQuestionsByCategory] = useState({ environmental: [], social: [], economic: [] })
	const [answers, setAnswers] = useState({ environmental: {}, social: {}, economic: {} })
	const [filesToDelete, setFilesToDelete] = useState([])
	const [preSalvedPathId, setPreSalvedPathId] = useState(null)

	const initAnswers = (version, questions) => {
		if (localStorage.avesAnswers && localStorage.avesQuestionaryVersion) {
			const storeAnswers = JSON.parse(localStorage.avesAnswers)
			if (localStorage.avesQuestionaryVersion === version) {
				setAnswers(storeAnswers)
				return
			}
		}
		const answers = {
			environmental: {},
			social: {},
			economic: {}
		}
		for (const q of questions.environmental) {
			answers.environmental[q.position] = {
				id: q.id,
				question: q.question,
				points: q.points,
				answer: null
			}
		}
		for (const q of questions.social) {
			answers.social[q.position] = {
				id: q.id,
				question: q.question,
				points: q.points,
				answer: null
			}
		}
		for (const q of questions.economic) {
			answers.economic[q.position] = {
				id: q.id,
				question: q.question,
				points: q.points,
				answer: null
			}
		}
		setAnswers(answers)
		localStorage.avesAnswers = JSON.stringify(answers)
		localStorage.avesQuestionaryVersion = version
	}

	const getData = async () => {
		setLoading(true)
		const result = await requestHandler('get_questions_active_questionary', {})
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.data, type: 'danger' })
			return
		}
		const questions = result.data.questions
		const settings = result.data.settings
		const environmental = questions.filter(q => q.category === 'environmental').sort((a, b) => a.position - b.position)
		const social = questions.filter(q => q.category === 'social').sort((a, b) => a.position - b.position)
		const economic = questions.filter(q => q.category === 'economic').sort((a, b) => a.position - b.position)
		if (id !== undefined && id !== 'blank') {
			getPresalvedAnswers()
		} else {
			initAnswers(settings.version, { environmental, social, economic })
		}
		setQuestionsByCategory({ environmental, social, economic })
		setPositions(environmental.map(q => q.position))
		setCurrentCategory('environmental')
		setVersion(settings.version)
	}

	const getPresalvedAnswers = async () => {
		setLoading(true)
		const result = await requestHandler('get_results', { id })
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.data, type: 'danger' })
			return
		}
		const answers = {
			environmental: {},
			social: {},
			economic: {}
		}
		const answersQuestions = result.data.answers
		for (const q of answersQuestions.environmental) {
			answers.environmental[q.position] = {
				id: q.id,
				question: q.question,
				points: q.points,
				answer: q.answer
			}
		}
		for (const q of answersQuestions.social) {
			answers.social[q.position] = {
				id: q.id,
				question: q.question,
				points: q.points,
				answer: q.answer
			}
		}
		for (const q of answersQuestions.economic) {
			answers.economic[q.position] = {
				id: q.id,
				question: q.question,
				points: q.points,
				answer: q.answer
			}
		}
		setAnswers(answers)
		setPreSalvedPathId(result.data.result.path_id)
		localStorage.avesAnswers = JSON.stringify(answers)
		localStorage.avesQuestionaryVersion = result.data.result.questionary_version
	}

	const nextStep = () => {
		let canNext = true
		// Vamos a validar que las preguntas estén respondidas y las que necesiten archivos, tengan archivos
		for (const q of questionsByCategory[currentCategory]) {
			if (answers[currentCategory][q.position].answer === null) {
				canNext = false
				setAlert({ message: `La pregunta ${q.position} es obligatoria`, type: 'danger' })
				break
			}
			const options = JSON.parse(q.options)
			const selectedOption = options.find(o => o.id === answers[currentCategory][q.position].answer.id)
			if (selectedOption.filesEnabled === true && answers[currentCategory][q.position].answer.files.length === 0) {
				canNext = false
				setAlert({ message: `La pregunta ${q.position} necesita archivos adjuntos`, type: 'danger' })
				break
			}
		}
		// Si no paso la validación no continuamos
		if (canNext === false) {
			return
		}
		if (currentCategory === 'environmental') {
			setPositions(questionsByCategory.social.map(q => q.position))
			setCurrentCategory('social')
		} else if (currentCategory === 'social') {
			setPositions(questionsByCategory.economic.map(q => q.position))
			setCurrentCategory('economic')
		} else {
			setDataToSend({
				title: 'Enviar información',
				message: `¿Realmente desea enviar la información para ser evaluada?, tenga en cuenta que no podrá modificarla, si no deseas enviar la información puedes guardarla y enviarla en otro momento.`
			})
		}
	}

	const backStep = () => {
		if (currentCategory === 'environmental') {
			navigate('/home')
		} else if (currentCategory === 'economic') {
			setPositions(questionsByCategory.social.map(q => q.position))
			setCurrentCategory('social')
		} else if (currentCategory === 'social') {
			setPositions(questionsByCategory.environmental.map(q => q.position))
			setCurrentCategory('environmental')
		}
	}

	const onAnswer = answer => {
		const newAnswers = { ...answers }
		if (!newAnswers[currentCategory][answer.position]) {
			const q = questionsByCategory[currentCategory].find(q => q.position === answer.position)
			newAnswers[currentCategory][answer.position] = {
				id: q.id,
				question: q.question,
				points: q.points,
				answer: null
			}
		}
		newAnswers[currentCategory][answer.position].answer = answer.answer
		setAnswers(newAnswers)
		if (answer.answer !== null) {
			// No vamos a guardar en el local storage los archivos
			const answersCoppy = JSON.parse(JSON.stringify(newAnswers))
			answersCoppy[currentCategory][answer.position].answer.files = []
			localStorage.avesAnswers = JSON.stringify(answersCoppy)
		}
	}

	const onDeleteFileAnswer = (file, position) => {
		setFilesToDelete([...filesToDelete, file])
		const newAnswers = { ...answers }
		const newFiles = newAnswers[currentCategory][position].answer.files.filter(f => f !== file)
		newAnswers[currentCategory][position].answer.files = newFiles
		setAnswers(newAnswers)
	}

	const parseAnswersForUpload = async (answers, pathId) => {
		// Parsear y obtener archivos para la subida al server
		const finalAnswers = []
		for (const key of Object.keys(answers)) {
			// Obtener archivos para la subida
			const finalAnswer = { position: key, ...answers[key] }
			if (answers[key].answer?.files?.length > 0) {
				const formData = new FormData()
				for (const file of answers[key].answer.files) {
					if (typeof file === 'string') {
						// el archivo ya se encuentra en el server
						continue
					}
					formData.append('files[]', file.file, file.file.name)
				}
				const resultFiles = await requestHandlerFiles('upload_files_answer', formData, pathId)
				if (resultFiles.status !== true) {
					setLoading(false)
					setAlert({ message: resultFiles.data, type: 'danger' })
					return
				}
				const oldFiles = answers[key].answer.files.filter(f => typeof f === 'string')
				finalAnswer.answer.files = [...resultFiles.data, ...oldFiles]
			}
			finalAnswers.push(finalAnswer)
		}
		return finalAnswers
	}

	const sendData = async (isFinished = false) => {
		setLoading(true)
		let pathId = null
		if (id === undefined || id === 'blank') {
			// Vamos a indicarle al back que vamos a registrar unas nuevas respuestas
			const resultPrepare = await requestHandler('prepare_answers', {})
			if (resultPrepare.status !== true) {
				setLoading(false)
				setAlert({ message: resultPrepare.data, type: 'danger' })
				return
			}
			pathId = resultPrepare.data
		} else {
			pathId = preSalvedPathId
		}
		// Parsear y obtener archivos para la subida al server
		const answersToSave = {
			environmental: await parseAnswersForUpload(answers.environmental, pathId),
			social: await parseAnswersForUpload(answers.social, pathId),
			economic: await parseAnswersForUpload(answers.economic, pathId)
		}
		const data = {
			id,
			filesToDelete,
			answers: answersToSave,
			pathId,
			isFinished
		}
		const result = await requestHandler('save_answers', data)
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.data, type: 'danger' })
			return
		}
		localStorage.removeItem('avesAnswers')
		localStorage.removeItem('avesQuestionaryVersion')
		setAlert({ message: 'Respuestas guardadas correctamente', type: 'success' })
		navigate('/home')
	}

	useEffect(() => {
		getData()
	}, [])

	return (
		<div>
			<Confirm data={dataToSend} onConfirm={() => sendData(true)} onClose={() => setDataToSend(null)} />
			<small>Versión del cuestionario: {version}</small>
			<WidgetStepsQuestions positions={positions} answers={answers} currentCategory={currentCategory} />
			<div className="container-questions">
				{questionsByCategory[currentCategory].map(question => (
					<Question
						key={question.id}
						question={question}
						category={currentCategory}
						defaultAnswer={answers[currentCategory][question.position]?.answer}
						onAnswer={onAnswer}
						onDeleteFile={onDeleteFileAnswer}
					/>
				))}
			</div>
			<Row>
				<Btn
					title={currentCategory === 'environmental' ? 'Cancelar' : 'Anterior'}
					action={() => backStep()}
					styleType="secondary"
					style={{ width: 200 }}
				/>
				<Row style={{ width: 400, flex: 'none' }}>
					<Btn title="Guardar" action={() => sendData()} style={{ width: 170 }} />
					<Btn
						title={currentCategory === 'economic' ? 'Enviar información' : 'Siguiente'}
						action={() => nextStep()}
						style={{ width: 200 }}
					/>
				</Row>
			</Row>
		</div>
	)
}
