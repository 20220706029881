import { useState } from 'react'
import Input from 'components/Input'
import Btn from 'components/Btn'
import Link from 'components/Link'
import BtnGoogle from 'components/Auth/BtnGoogle'
import Version from 'components/Version'
import { setLoading, setAlert, setUser } from 'store'
import { requestHandler } from 'utils/requestHander'
import { useNavigate } from 'react-router-dom'

export default function Login() {
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const handleLogin = async () => {
		if (!email || !password) {
			setAlert({ type: 'danger', message: 'Todos los campos son obligatorios' })
			return
		}
		setLoading(true)
		const result = await requestHandler('auth', { email, password })
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				type: 'danger',
				message: result.data
			})
			return
		}
		setUser(result.data)
		navigate('/home')
	}

	return (
		<div className="auth-container">
			<div className="form">
				<div className="container-logos">
					<img src={require('assets/img/logo.png')} alt="Logo" />
					<img src={require('assets/img/logo-cornare.png')} alt="Logo" />
				</div>
				<div className="container-text">
					<h1>Acceder</h1>
					<p>Ingresa tu dirección de e-mail y contraseña para acceder a tu cuenta</p>
				</div>
				<Input
					type="email"
					name="email"
					label="E-mail"
					placeholder="Ingresa tu e-mail"
					onChange={e => setEmail(e.target.value)}
					value={email}
					required
				/>
				<Input
					type="password"
					name="password"
					label="Contraseña"
					placeholder="Ingresa tu Contraseña"
					onChange={e => setPassword(e.target.value)}
					value={password}
					required
				/>
				<Btn title="Entrar" action={() => handleLogin()} />
				<Link title="Recuperar contraseña" to={'forgot-password'} />
				<Link title="Registrarse" to={'register'} />
				<BtnGoogle />
				<Version />
			</div>
		</div>
	)
}
