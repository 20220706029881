import Row from 'components/Row'
import Card from 'components/Card'
import Table from 'components/Table'
import { useNavigate } from 'react-router-dom'
export default function WidgetsTopPositionsAndHistory({ top10, lastAnswers, version }) {
	const navigate = useNavigate()
	const columns = [
		{
			header: '#',
			accessorKey: 'position',
			cell: ({ row }) => <span className={`position-table p${row.original.position}`}>{row.original.position}</span>
		},
		{ header: 'Acueducto', accessorKey: 'name' },
		{ header: 'Subregión', accessorKey: 'subregion' },
		{ header: 'Puntos', accessorKey: 'points' }
	]
	const columns2 = [
		{ header: 'Fecha de respuesta', accessorKey: 'updated_at' },
		{ header: 'Versión', accessorKey: 'questionary_version' },
		{ header: 'Total puntos', accessorKey: 'total_points' }
	]
	return (
		<div className="widgets-top-positions-and-history">
			<Row>
				<Card title="Top 10" action={{ action: () => navigate('/top-aqueducts/' + version), title: 'Ver todo' }}>
					<Table columns={columns} data={top10} filters={false} pagination={false} />
				</Card>
				<Card title="Historial de cuestionarios" action={{ action: () => navigate('/history'), title: 'Ver todo' }}>
					<Table columns={columns2} data={lastAnswers} filters={false} pagination={false} />
				</Card>
			</Row>
		</div>
	)
}
