import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user'
import globalFlagsReducer from './globalFlags'
const store = configureStore({
	reducer: {
		user: userReducer.reducer,
		globalFlags: globalFlagsReducer.reducer
	}
})

const d = store.dispatch

const storeManager = {
	// Global Flags
	// ------------
	setAlert: data => d(globalFlagsReducer.actions.setAlert(data)),
	setConfirm: data => d(globalFlagsReducer.actions.setConfirm(data)),
	setLoading: data => d(globalFlagsReducer.actions.setLoading(data)),
	setModal: data => d(globalFlagsReducer.actions.setModal(data)),
	// User
	// ----
	setUser: data => d(userReducer.actions.setUser(data)),
	resetUser: data => d(userReducer.actions.resetUser(data))
}
export const {
	// Global Flags
	setAlert,
	setConfirm,
	setLoading,
	setModal,
	// User
	setUser,
	resetUser
} = storeManager
export default store
