import Row from 'components/Row'
import Card from 'components/Card'
import {
	ComposedChart,
	Bar,
	Line,
	Rectangle,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts'
import { percentages } from 'utils/mixins'
export default function WidgetGraphAndPosition({ data, totalPoints, points, position }) {
	return (
		<div className="graphs-and-position">
			<Row>
				<Card title="Tus resultados en porcentaje">
					<div className="container-graph">
						<ResponsiveContainer width="100%" height="100%">
							<ComposedChart
								width={500}
								height={300}
								data={[
									{
										name: 'Ambiental',
										obtenido: data.environmental,
										ideal: percentages.environmental
									},
									{
										name: 'Social',
										obtenido: data.social,
										ideal: percentages.social
									},
									{
										name: 'Económico',
										obtenido: data.economic,
										ideal: percentages.economic
									}
								]}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Legend />
								<Bar dataKey="ideal" fill="#527a78" activeBar={<Rectangle fill="#527a78" stroke="blue" />} />
								<Bar dataKey="obtenido" fill="#25f9ed" activeBar={<Rectangle fill="#25f9ed" stroke="blue" />} />
								<Line type="monotone" dataKey="obtenido" stroke="blue" />
							</ComposedChart>
						</ResponsiveContainer>
					</div>
				</Card>
				<Card title="Tu posición actual">
					<img src={require('assets/img/star.png')} alt="Star" />
					<strong>{position}</strong>
					<p>
						{totalPoints} puntos de {points}
					</p>
				</Card>
			</Row>
		</div>
	)
}
