import Card from 'components/Card'
import { percentages } from 'utils/mixins'
export default function WidgetStepsQuestions({ positions, answers, currentCategory }) {
	const categories = {
		environmental: {
			title: 'Dimensión ambiental',
			percentege: percentages.environmental,
			icon: require('assets/img/enviorment.png')
		},
		social: {
			title: 'Dimensión social',
			percentege: percentages.social,
			icon: require('assets/img/social.png')
		},
		economic: {
			title: 'Dimensión económica',
			percentege: percentages.economic,
			icon: require('assets/img/economic.png')
		}
	}
	const activePositions = Object.keys(answers[currentCategory]).filter(
		key => answers[currentCategory][key].answer !== null
	)
	return (
		<Card>
			<h2 className="title-question-widget">
				<img src={categories[currentCategory].icon} alt={categories[currentCategory].title} />
				{categories[currentCategory].title}
				<span className={currentCategory}>{categories[currentCategory].percentege}%</span>
			</h2>
			<div className={`step-questions ${currentCategory}`}>
				{positions.map((position, index) => (
					<div key={index} className={`step ${activePositions.includes(position) ? 'active' : ''}`}>
						{position}
					</div>
				))}
			</div>
		</Card>
	)
}
