import { useEffect, useState } from 'react'
import Card from 'components/Card'
import Table from 'components/Table'
import { requestHandler } from 'utils/requestHander'
import { setAlert, setLoading } from 'store'
import { percentages, getPercentageOf } from 'utils/mixins'
import { useNavigate } from 'react-router-dom'
import Title from 'components/Title'
export default function HistoryByAqueduct() {
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const columns = [
		{ header: 'Fecha de respuesta', accessorKey: 'updated_at' },
		{ header: 'Versión', accessorKey: 'questionary_version' },
		{
			header: 'Ambiental',
			accessorKey: 'percent_environmental',
			cell: ({ row }) => `${row.original.percent_environmental}%`
		},
		{ header: 'Social', accessorKey: 'percent_social', cell: ({ row }) => `${row.original.percent_social}%` },
		{ header: 'Económico', accessorKey: 'percent_economic', cell: ({ row }) => `${row.original.percent_economic}%` },
		{ header: 'Total', accessorKey: 'percent_total', cell: ({ row }) => `${row.original.percent_total}%` },
		{ header: 'Total puntos', accessorKey: 'total_points' },
		{
			header: 'Acciones',
			accessorKey: 'actions',
			actions: {
				show: data => navigate(`/results/${data.id}`)
			}
		}
	]
	const getData = async () => {
		setLoading(true)
		const result = await requestHandler('get_history_answers', {})
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.data, type: 'danger' })
			return
		}
		const historyData = result.data.map(data => {
			data.percent_environmental = getPercentageOf(
				data.total_environmental,
				percentages.environmental,
				data.environmental
			)
			data.percent_social = getPercentageOf(data.total_social, percentages.social, data.social)
			data.percent_economic = getPercentageOf(data.total_economic, percentages.economic, data.economic)
			data.percent_total = getPercentageOf(data.total_points, 100, data.points)
			return data
		})
		setData(historyData)
	}
	useEffect(() => {
		getData()
	}, [])
	return (
		<>
			<Title text="Historial de cuestionarios" back />
			<Card>
				<Table columns={columns} data={data} filters={false} />
			</Card>
		</>
	)
}
