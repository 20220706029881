import { useNavigate } from 'react-router-dom'
function Title({ text, back = false, actionBack = null, style = {} }) {
	const navigate = useNavigate()
	return (
		<div className="title" style={style}>
			{back && (
				<a
					href="#"
					onClick={e => {
						e.preventDefault()
						if (actionBack) {
							actionBack()
						} else {
							navigate(-1)
						}
					}}></a>
			)}
			<h1>{text}</h1>
		</div>
	)
}
export default Title
