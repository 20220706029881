import { useState, useEffect } from 'react'
import Card from 'components/Card'
import Row from 'components/Row'
import Input from 'components/Input'
import Btn from 'components/Btn'
import { useSelector } from 'react-redux'
import { setLoading, setAlert, setUser } from 'store'
import { requestHandler } from 'utils/requestHander'

export default function Profile() {
	const user = useSelector(state => state.user)
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [passwordConfirmation, setPasswordConfirmation] = useState('')
	const sendData = async () => {
		if (!name || !email) {
			setAlert({ type: 'danger', message: 'Ingresa el nombre y el e-mail' })
			return
		}
		if (name.length < 3) {
			setAlert({ type: 'danger', message: 'El nombre es demasiado corto' })
			return
		}
		if (password) {
			if (password.length < 6) {
				setAlert({ type: 'danger', message: 'La contraseña es demasiado corta' })
			}
			if (password !== passwordConfirmation) {
				setAlert({ type: 'danger', message: 'Las contraseñas no coinciden' })
				return
			}
		}
		setLoading(true)
		const result = await requestHandler('update_profile', {
			name,
			email,
			password: password !== '' ? password : null
		})
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				type: 'danger',
				message: result.data
			})
			return
		}
		setAlert({
			type: 'success',
			message: '¡Perfil actualizado!'
		})
		const newUser = JSON.parse(JSON.stringify(user))
		newUser.name = name
		newUser.email = email
		setUser(newUser)
	}

	useEffect(() => {
		if (user) {
			setName(user.name)
			setEmail(user.email)
		}
	}, [user])

	return (
		<Card title="Actualizar perfil">
			<Row>
				<Input
					type="text"
					name="name"
					label="Nombre"
					placeholder="Ingresa tu nombre"
					onChange={e => setName(e.target.value)}
					value={name}
					style={{ marginRight: 25 }}
					required
				/>
				<Input
					type="text"
					name="email"
					label="E-mail"
					placeholder="Ingresa tu e-mail"
					onChange={e => setEmail(e.target.value)}
					value={email}
					required
				/>
			</Row>
			<Row>
				<Input
					type="password"
					name="password"
					label="Contraseña"
					placeholder="Ingresa tu contraseña"
					onChange={e => setPassword(e.target.value)}
					value={password}
					style={{ marginRight: 25 }}
					required
				/>
				<Input
					type="password"
					name="passwordConfirmation"
					label="Confirmar contraseña"
					placeholder="Ingresa tu contraseña nuevamente"
					onChange={e => setPasswordConfirmation(e.target.value)}
					value={passwordConfirmation}
					required
				/>
			</Row>
			<Btn title="Actualizar perfil" action={() => sendData()} style={{ width: 200 }} />
		</Card>
	)
}
