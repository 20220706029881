import { useState } from 'react'
import Input from 'components/Input'
import Card from 'components/Card'
import Row from 'components/Row'
import Btn from 'components/Btn'
export default function WidgetResumenAdmin({ versions, subregions, results, onFilter }) {
	const [selectedVersion, setSelectedVersion] = useState(versions[versions.length - 1])
	const [selectedSubregion, setSelectedSubregion] = useState(subregions[subregions.length - 1])
	const filter = () => {
		if (selectedVersion && selectedSubregion) {
			onFilter({ version: selectedVersion, subregion: selectedSubregion })
		}
	}

	return (
		<Card style={{ marginBottom: 20 }}>
			<Row>
				<Input
					style={{ marginRight: 20 }}
					type="select"
					name="selectedVersion"
					label="Versión del cuestionario"
					placeholder="Selecciona..."
					options={versions}
					onChange={val => setSelectedVersion(val)}
					value={selectedVersion}
				/>
				<Input
					type="select"
					name="selectedSubregion"
					label="Subregión"
					placeholder="Selecciona..."
					options={subregions}
					onChange={val => setSelectedSubregion(val)}
					value={selectedSubregion}
				/>
			</Row>
			<Btn style={{ width: 200 }} type="primary" title="Filtrar" action={filter} />
			{results && (
				<>
					<hr />
					<br />
					<strong>Fechas del cuestionario: 10/10/2024 - 10/10/2024</strong>
					<br />
					<strong>Cantidad de acueductos: 15</strong>
					<br />
					<strong>Cantidad de respuestas: 2</strong>
					<br />
				</>
			)}
		</Card>
	)
}
