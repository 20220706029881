import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Login from 'views/Auth/Login'
import Register from 'views/Auth/Register'
import ForgotPassword from 'views/Auth/ForgotPassword'
import Notfound from 'views/404'
import Profile from 'views/Profile'
import CreateUpdateAqueduct from 'views/Aqueducts/CreateUpdateAqueduct'
import Container from 'views/Dashboard/Container'
import Home from 'views/Dashboard/Home'
import Aqueducts from 'views/Aqueducts/Index'
import ShowAqueduct from 'views/Aqueducts/Show'
import Questions from 'views/Questions/Index'
import CreateUpdateQuestion from 'views/Questions/CreateUpdateQuestion'
import Questionary from 'views/Questions/Questionary'
import Results from 'views/Questions/Results'
import HistoryByAqueduct from 'views/Questions/HistoryByAqueduct'
import TopAqueducts from 'views/Questions/TopAqueducts'

function AuthRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Login />} />
			<Route path="/register" element={<Register />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="*" element={<Notfound />} />
		</Routes>
	)
}

function DashboardRoutes({ user }) {
	return (
		<Routes>
			<Route path="/" element={<Container />}>
				<Route path="home" element={<Home />} />
				<Route path="profile" element={<Profile />} />
				<Route path="results/:id" element={<Results />} />
				<Route path="top-aqueducts/:version" element={<TopAqueducts />} />
				{user.is_admin === 'Si' ? (
					<>
						<Route path="aqueducts" element={<Aqueducts />} />
						<Route path="aqueducts/show/:id" element={<ShowAqueduct />} />
						<Route path="questions" element={<Questions />} />
						<Route path="questions/create/:category" element={<CreateUpdateQuestion />} />
						<Route path="questions/edit/:category/:id" element={<CreateUpdateQuestion />} />
					</>
				) : (
					<>
						<Route path="aqueduct" element={<CreateUpdateAqueduct />} />
						<Route path="questionary/:id" element={<Questionary />} />
						<Route path="history" element={<HistoryByAqueduct />} />
					</>
				)}
			</Route>
			<Route path="*" element={<Notfound />} />
		</Routes>
	)
}

export default function AppRoutes() {
	const user = useSelector(state => state.user)
	return <Router>{user.name ? <DashboardRoutes user={user} /> : <AuthRoutes />}</Router>
}
