import { useState } from 'react'
import {
	useReactTable,
	getCoreRowModel,
	flexRender,
	getPaginationRowModel,
	getSortedRowModel,
	getFilteredRowModel
} from '@tanstack/react-table'
import Input from 'components/Input'
import Btn from 'components/Btn'
import { dateParse } from 'utils/mixins'
function Table({
	columns = [],
	data,
	actions = [],
	filters = true,
	customComponentFilters = null,
	hideSearchFilter = false,
	filtersStyle = {},
	pagination = true,
	sortBy = null,
	create = null,
	createOptions = null
}) {
	const [sorting, setSorting] = useState([])
	const [globalFilter, setGlobalFilter] = useState('')
	const [columnFilters, setColumnFilters] = useState([])
	const tableColumns = []
	for (const column of columns) {
		const finalColumn = {}
		if (column.accessorKey) {
			finalColumn.accessorKey = column.accessorKey
			if (column.accessorKey === 'created_at') {
				finalColumn.cell = ({ row }) => dateParse(row.original.created_at)
			}
			if (column.accessorKey === 'updated_at') {
				finalColumn.cell = ({ row }) => dateParse(row.original.updated_at)
			}
		}
		if (column.cell) {
			finalColumn.cell = column.cell
		}
		if (column.actions) {
			finalColumn.cell = ({ row }) => (
				<div className="btns-table">
					{column.actions.show && <Btn title="Ver" action={() => column.actions?.show(row.original, row.index)} />}
					{column.actions.edit && <Btn title="Editar" action={() => column.actions?.edit(row.original, row.index)} />}
					{column.actions.manage && (
						<Btn
							type="round"
							styleType="info"
							icon="settings"
							action={() => column.actions?.manage(row.original, row.index)}
						/>
					)}
					{column.actions.delete && (
						<Btn
							type="round"
							styleType="danger"
							title="Eliminar"
							action={() => column.actions?.delete(row.original, row.index)}
						/>
					)}
				</div>
			)
		}
		if (column.header) {
			finalColumn.header = column.header
		}
		if (Object.keys(finalColumn).length > 0) {
			tableColumns.push(finalColumn)
		}
	}
	const table = useReactTable({
		columns: tableColumns,
		data,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: setSorting,
		onGlobalFilterChange: setGlobalFilter,
		onColumnFiltersChange: setColumnFilters,
		state: {
			sorting,
			columnFilters,
			globalFilter
		}
	})
	const optionsFilterBy = []
	for (const column of columns) {
		if (!['Acciones'].includes(column.header)) {
			// Omitir ciertas columnas para el filtro
			optionsFilterBy.push({ value: column.accessorKey, label: `Filtrar por ${column.header}` })
		}
	}
	if (sortBy) {
		data = data.sort((a, b) => {
			return b[sortBy] - a[sortBy]
		})
	}
	const filterTable = (val = '', cols = []) => {
		cols = cols.map(col => {
			return {
				id: col.id,
				value: val
			}
		})
		setColumnFilters(cols)
		setGlobalFilter(val)
	}

	return (
		<div className="table">
			<div className="actions-and-filters">
				<div className="filters" style={filtersStyle}>
					{filters && (
						<>
							{!hideSearchFilter && (
								<Input
									type="search"
									value={globalFilter}
									onChange={e => {
										filterTable(e.target.value, columnFilters)
									}}
									placeholder="Buscar..."
								/>
							)}
							{customComponentFilters !== null && customComponentFilters({ filterTable, setColumnFilters })}
						</>
					)}
				</div>
				{(actions.length > 0 || create || createOptions) && (
					<div className="actions">
						{actions.map((action, i) => (
							<Btn key={i} title={action.title} styleType="primary" action={action.action} />
						))}
						{create && <Btn title={create.title} styleType="primary" action={create.action} />}
						{createOptions && (
							<Btn
								title={createOptions.title}
								styleType="primary"
								action={createOptions.action}
								type="dropdown"
								options={createOptions.options}
							/>
						)}
					</div>
				)}
			</div>
			<table>
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header, i) => (
								<th key={'th_' + i}>
									{filters ? (
										<button
											onClick={header.column.getToggleSortingHandler()}
											className={{ asc: 'up', desc: 'down' }[header.column.getIsSorted() ?? null]}>
											<span>{header.column.columnDef.header}</span>
										</button>
									) : (
										<button>
											<span>{header.column.columnDef.header}</span>
										</button>
									)}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => (
						<tr key={row.id}>
							{row.getVisibleCells().map((cell, i) => (
								<td key={i}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			{pagination && <Pargination table={table} />}
		</div>
	)
}
function Pargination({ table }) {
	// Paginación de la tabla
	let paginationButtons = []
	const paginationGoups = []
	const paginationGoupLimit = 10
	const currentPage = table.getState().pagination.pageIndex
	const countPageSize = table.getPageCount()
	for (let i = 0; i < countPageSize; i++) {
		if (i > 0 && i % paginationGoupLimit === 0) {
			paginationButtons.push(<span>...</span>)
			paginationGoups.push(paginationButtons)
			paginationButtons = []
		}
		paginationButtons.push(
			<button className={currentPage === i ? 'active' : ''} key={i} onClick={() => table.setPageIndex(i)}>
				{i + 1}
			</button>
		)
	}
	if (paginationButtons.length > 0) {
		if (paginationGoups.length > 0) {
			paginationButtons.unshift(<span>...</span>)
		}
		paginationGoups.push(paginationButtons)
		paginationButtons = []
	}
	const getCurrentPaginationGroup = () => {
		for (const i in paginationGoups) {
			if (paginationGoups[i].findIndex(u => u.key === String(currentPage)) !== -1) {
				return paginationGoups[i]
			}
		}
	}
	const optionsShowItemsPage = [
		{ value: 10, label: 'Mostrar 10 filas' },
		{ value: 20, label: 'Mostrar 20 filas' },
		{ value: 40, label: 'Mostrar 40 filas' },
		{ value: 50, label: 'Mostrar 50 filas' },
		{ value: 100, label: 'Mostrar 100 filas' },
		{ value: 200, label: 'Mostrar 200 filas' },
		{ value: 300, label: 'Mostrar 300 filas' },
		{ value: 500, label: 'Mostrar 500 filas' },
		{ value: 1000, label: 'Mostrar 1000 filas' }
	]
	if (countPageSize > 0) {
		return (
			<div className="pagination">
				<strong>
					{' '}
					Página {currentPage + 1} de {countPageSize}
				</strong>
				<div className="controls">
					<button
						className="firts-page"
						onClick={() => table.setPageIndex(0)}
						disabled={!table.getCanPreviousPage()}></button>
					<button className="prev" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}></button>
					{getCurrentPaginationGroup().map(button => button)}
					<button className="next" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}></button>
					<button
						className="last-page"
						onClick={() => table.setPageIndex(countPageSize - 1)}
						disabled={!table.getCanNextPage()}></button>
				</div>
				<Input
					type="select"
					value={optionsShowItemsPage[0]}
					options={optionsShowItemsPage}
					onChange={e => {
						if (e) {
							table.setPageSize(Number(e.value))
						}
					}}
				/>
			</div>
		)
	}
}
export default Table
