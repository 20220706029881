import { NavLink } from 'react-router-dom'
export default function Notfound() {
	return (
		<div className="not-found">
			<span>¡Ooops!</span>
			<h1>404</h1>
			<p>No encontramos nada por aquí</p>
			<NavLink to="/" className="link">
				Volver al inicio
			</NavLink>
		</div>
	)
}
