import { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Card from 'components/Card'
import Row from 'components/Row'
import Btn from 'components/Btn'
import { setAlert, setLoading } from 'store'
import { requestHandler } from 'utils/requestHander'

export default function Reorder({ questions, onSuccess, onCancel, title }) {
	const [questionsOrder, setQuestionsOrder] = useState([])
	const onDragEnd = result => {
		if (!result.destination) {
			return
		}
		const newOrder = reorder(questionsOrder, result.source.index, result.destination.index)
		const newQuestionsOrder = newOrder.map((item, i) => {
			item.position = String(Number(i) + 1)
			return item
		})
		setQuestionsOrder(newQuestionsOrder)
	}
	const sendReorder = async () => {
		setLoading(true)
		const result = await requestHandler('reorder_questions', { questions: questionsOrder })
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.data, type: 'danger' })
			return
		}
		setAlert({ message: 'Preguntas reordenadas correctamente', type: 'success' })
		onSuccess()
	}

	useEffect(() => {
		setQuestionsOrder(JSON.parse(JSON.stringify(questions)))
	}, [questions])

	return (
		<>
			<Card title={'Reordenar preguntas: ' + title}>
				<br />
				<p>Para reordenar la preguntas, puedes arrastrar y soltar las preguntas en el orden deseado.</p>
				<br />
				<Row>
					<Btn title="Cancelar" styleType="secondary" action={() => onCancel()} style={{ width: 150 }} />
					<Btn title="Guardar" action={() => sendReorder()} style={{ width: 150 }} />
				</Row>
			</Card>
			<br />
			<Card>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{provided => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{questionsOrder.map((item, index) => (
									<Draggable key={index.position} draggableId={item.position} index={index}>
										{(provided, snapshot) => (
											<div
												className="item-drag drag-questions"
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
												<div className="container-dragger">
													<span></span>
													<span></span>
													<span></span>
												</div>
												<span className="round-positions">{item.position}</span>
												<div className="info-drag">
													<p>{item.question}</p>
													<strong>Puntos: {item.points}</strong>
												</div>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</Card>
		</>
	)
}
const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	padding: 8 * 2,
	margin: `0 0 ${8}px 0`,
	borderRadius: 4,
	borderWidth: 1,
	borderColor: '#eaeaea',
	borderStyle: 'solid',
	position: 'relative',
	background: isDragging ? '#23c8be' : '#fff',
	...draggableStyle
})
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)
	return result
}
