import Card from 'components/Card'
export default function WidgetWelcomeAndWaitForQuestionary() {
	return (
		<Card>
			<h1 className="welcome">
				Te damos la viencenida a <b>AVES</b>
			</h1>
			<p>Estamos preparando un cuestionario para tu acueducto</p>
			<p>Una vez lo tengamos listo lo podrás ver aquí</p>
		</Card>
	)
}
