const version = require('credentials/version.json')
export default function Version() {
	return (
		<div className="version">
			<p>
				Version: {version.major}.{version.minor}.{version.patch} - {version.date}
			</p>
		</div>
	)
}
