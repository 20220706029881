import { useState, useEffect } from 'react'
import Card from 'components/Card'
import Row from 'components/Row'
import Input from 'components/Input'
import Btn from 'components/Btn'
import { setLoading, setAlert } from 'store'
import { requestHandler } from 'utils/requestHander'
import { useParams, useNavigate } from 'react-router-dom'

export default function Show() {
	const navigate = useNavigate()
	const { id } = useParams()
	const [aqueduct, setAquedutc] = useState({})
	const getData = async () => {
		setLoading(true)
		const result = await requestHandler('get_aqueduct', { id })
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				message: result.data,
				type: 'danger'
			})
			return
		}
		const aqueductData = {
			name: result.data.name,
			subregion: result.data.subregion,
			municipalities: JSON.parse(result.data.municipalities),
			sidewalks: JSON.parse(result.data.sidewalks),
			subResidential: result.data.subResidential,
			subCommertial: result.data.subCommertial,
			legalRepresentative: result.data.legalRepresentative,
			phoneRepresentative: result.data.phone
		}
		setAquedutc(aqueductData)
	}
	useEffect(() => {
		getData()
	}, [])

	return (
		<Card title="Detalles del aqueducto">
			<Row>
				<Input
					type="text"
					name="name"
					label="Nombre del acueducto"
					value={aqueduct.name}
					disabled
					style={{ marginRight: 25 }}
				/>
				<Input type="text" name="subregion" label="Subregión" value={aqueduct.subregion} disabled />
			</Row>
			<Row>
				<Input
					type="select"
					name="selectedMunicipalities"
					label="Muicipios"
					multi={true}
					options={[]}
					value={aqueduct.municipalities}
					style={{ marginRight: 25 }}
					disabled
				/>
				<Input
					type="select"
					name="selectedSidewalks"
					label="Veredas"
					multi={true}
					options={[]}
					value={aqueduct.sidewalks}
					disabled
				/>
			</Row>
			<Row>
				<Input
					type="number"
					name="subResidential"
					label="Suscriptores residenciales"
					value={aqueduct.subResidential}
					disabled
					style={{ marginRight: 25 }}
				/>
				<Input
					type="number"
					name="subCommertial"
					label="Suscriptores industriales y comerciales"
					value={aqueduct.subCommertial}
					disabled
				/>
			</Row>
			<Row>
				<Input
					type="text"
					name="legalRepresentative"
					label="Nombre del representante legal"
					value={aqueduct.legalRepresentative}
					disabled
					style={{ marginRight: 25 }}
				/>
				<Input
					type="number"
					name="phoneRepresentative"
					label="Teléfono del representante legal"
					value={aqueduct.phoneRepresentative}
					disabled
				/>
			</Row>
			<Btn title="Volver" action={() => navigate(-1)} style={{ width: 200 }} />
		</Card>
	)
}
