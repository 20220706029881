import { useState } from 'react'
import Card from 'components/Card'
const Tabs = ({ tabs }) => {
	const [activeTab, setActiveTab] = useState(0)
	const handleTabClick = index => {
		setActiveTab(index)
	}
	return (
		<div className="tabs">
			<div className="tab-buttons">
				{tabs.map((tab, index) => (
					<button
						key={index}
						type="button"
						className={index === activeTab ? 'primary' : 'simple'}
						onClick={() => handleTabClick(index)}>
						{tab.label}
					</button>
				))}
			</div>
			{tabs.map((tab, index) => (
				<div key={index} className={`tab-content ${index === activeTab ? 'active' : ''}`}>
					<Card>{tab.component}</Card>
				</div>
			))}
		</div>
	)
}
export default Tabs
