import { useState, useEffect } from 'react'
import Row from 'components/Row'
import Btn from 'components/Btn'
import Input from 'components/Input'
import { requestHandler } from 'utils/requestHander'
import { setAlert, setLoading } from 'store'
import { millisecondsToDatetimeLocal, datetimeLocalToMilliseconds } from 'utils/mixins'
export default function SettingsQuestionary({ show, onClose, onSuccess, settings }) {
	const [start, setStart] = useState('')
	const [end, setEnd] = useState('')
	const [desc, setDesc] = useState('')
	const sendSettings = async () => {
		// datetimeLocalToMilliseconds
		const startMiliseconds = datetimeLocalToMilliseconds(start)
		const endMiliseconds = datetimeLocalToMilliseconds(end)
		if (startMiliseconds > endMiliseconds) {
			setAlert({ message: 'La fecha de inicio no puede ser mayor a la fecha de fin', type: 'danger' })
			return
		}
		setLoading(true)
		const result = await requestHandler('save_questionary', {
			startDate: startMiliseconds,
			endDate: endMiliseconds,
			description: desc
		})
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.data, type: 'danger' })
			return
		}
		setAlert({ message: 'Configuración guardada correctamente', type: 'success' })
		onSuccess({
			startDate: String(startMiliseconds),
			endDate: String(endMiliseconds),
			description: desc
		})
		onClose()
	}
	useEffect(() => {
		if (show) {
			setStart(millisecondsToDatetimeLocal(settings.startDate))
			setEnd(millisecondsToDatetimeLocal(settings.endDate))
			setDesc(settings.description)
		}
	}, [show])

	if (!show) return null
	return (
		<div className="modal">
			<div className="container-modal">
				<h2>Configuración de cuestionario</h2>
				<p>Ingresa la fecha de inicio y fin para la activación del cuestionario</p>
				<br />
				<Input label="Fecha de inicio" type="datetime-local" value={start} onChange={e => setStart(e.target.value)} />
				<Input label="Fecha de fin" type="datetime-local" value={end} onChange={e => setEnd(e.target.value)} />
				<Input label="Descripción (opcional)" type="textarea" value={desc} onChange={e => setDesc(e.target.value)} />
				<Row style={{ width: '100%', marginTop: 25 }}>
					<Btn action={() => onClose()} style={{ marginRight: 70 }} styleType="secondary" title={'Cancelar'} />
					<Btn action={() => sendSettings()} title={'Guardar'} />
				</Row>
			</div>
		</div>
	)
}
