import { useNavigate } from 'react-router-dom'

function Link({ to, title, action = null }) {
	const navigate = useNavigate()
	const handleClick = e => {
		e.preventDefault()
		if (action) {
			action()
		} else {
			if (to.indexOf('http') > -1) {
				window.open(to, '_blank')
			} else {
				navigate(to)
			}
		}
	}
	return (
		<a href="#" onClick={handleClick} className="link">
			{title}
		</a>
	)
}
export default Link
