import { useEffect, useState } from 'react'
import WidgetResumenAdmin from 'components/Dashboard/WidgetResumenAdmin'
import Title from 'components/Title'
import Card from 'components/Card'
import Table from 'components/Table'
import { requestHandler } from 'utils/requestHander'
import { setLoading, setAlert } from 'store'
import { percentages, getPercentageOf, dateParse } from 'utils/mixins'
import { useNavigate } from 'react-router-dom'
import { downloadExcel } from 'react-export-table-to-excel'
import Btn from 'components/Btn'
export default function Admin() {
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const [version, setVersion] = useState('')
	const [versions, setVersions] = useState([])
	const [subregions, setSubregions] = useState('')
	const columns = [
		{
			header: 'Posición',
			accessorKey: 'position',
			cell: ({ row }) => <span className={`position-table p${row.original.position}`}>{row.original.position}</span>
		},
		{ header: 'Acueducto', accessorKey: 'aqueduct_name' },
		{ header: 'Subregión', accessorKey: 'subregion' },
		{
			header: 'Ambiental',
			accessorKey: 'percent_enviormental',
			cell: ({ row }) => `${row.original.percent_enviormental}%`
		},
		{ header: 'Social', accessorKey: 'percent_social', cell: ({ row }) => `${row.original.percent_social}%` },
		{ header: 'Económico', accessorKey: 'percent_economic', cell: ({ row }) => `${row.original.percent_economic}%` },
		{ header: 'Total', accessorKey: 'percent_total', cell: ({ row }) => `${row.original.percent_total}%` },
		{
			header: 'Acciones',
			accessorKey: 'actions',
			actions: {
				show: data => navigate(`/results/${data.answer_id}`)
			}
		}
	]
	const getVersions = async () => {
		setLoading(true)
		const result = await requestHandler('get_versions_questionary', {})
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.message, type: 'danger' })
			return
		}
		const versionsToSet = result.data.map(item => {
			return { value: item.questionary_version, label: item.questionary_version }
		})
		setVersions(versionsToSet)
		if (versionsToSet.length && version === '') {
			setVersion(versionsToSet[0].value)
			getData({ version: versionsToSet[0] })
		}
	}
	const getSubregions = async () => {
		setLoading(true)
		const result = await requestHandler('get_subregions', {})
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				message: result.data,
				type: 'danger'
			})
			return
		}
		const subr = result.data.map((s, i) => ({
			id: i,
			label: s
		}))
		subr.push({ id: subr.length, label: 'Todas' })
		setSubregions(subr)
	}
	const getData = async ({ version, subregion }) => {
		setLoading(true)
		const data = {
			version: version.value
		}
		if (subregion && subregion.label !== 'Todas') {
			data.subregion = subregion.label
		}
		const result = await requestHandler('get_top_aqueducts', data)
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.message, type: 'danger' })
			return
		}
		const topResult = result.data.map(item => {
			item.percent_enviormental = getPercentageOf(
				item.total_environmental,
				percentages.environmental,
				item.environmental
			)
			item.percent_social = getPercentageOf(item.total_social, percentages.social, item.social)
			item.percent_economic = getPercentageOf(item.total_economic, percentages.economic, item.economic)
			item.percent_total = getPercentageOf(item.total_points, 100, item.points)
			return item
		})
		setData(topResult)
	}
	const downloadXlsx = () => {
		// Armamos los encabezados del excel
		const header = [
			'Acueducto',
			'Subregión',
			'Fecha de respuesta',
			'Puntos Ambiental',
			'Puntos Social',
			'Puntos Económico',
			'Puntos Total',
			'Porcentaje Ambiental',
			'Porcentaje Social',
			'Porcentaje Económico',
			'Porcentaje Total',
			'Posición'
		]
		// Armar el cuerpo del excel
		const body = []
		for (const answer of data) {
			const item = []
			item.push(answer.aqueduct_name)
			item.push(answer.subregion)
			item.push(dateParse(answer.created_at))
			item.push(answer.total_environmental)
			item.push(answer.total_social)
			item.push(answer.total_economic)
			item.push(answer.total_points)
			item.push(answer.percent_enviormental + '%')
			item.push(answer.percent_social + '%')
			item.push(answer.percent_economic + '%')
			item.push(answer.percent_total + '%')
			item.push(answer.position)
			body.push(item)
		}
		const fileName = 'Resultados_version_' + version
		downloadExcel({ fileName, sheet: 'Resultados', tablePayload: { header, body } })
	}

	useEffect(() => {
		getVersions()
		getSubregions()
	}, [])
	return (
		<>
			<Title text="Inicio" />
			<WidgetResumenAdmin versions={versions} subregions={subregions} onFilter={getData} />
			<Card>
				{data.length > 0 && <Btn title="Descargar Excel" action={downloadXlsx} style={{ width: 200 }} />}
				<Table columns={columns} data={data} filters={false} />
			</Card>
		</>
	)
}
