import Card from 'components/Card'
import Input from 'components/Input'
import { URL_FILES } from 'utils/requestHander'
export default function Answer({ data, category, idAqueduct, pathId }) {
	const filesIcons = {
		pdf: 'pdf',
		xls: 'xls',
		xlsx: 'xls',
		docx: 'doc',
		png: 'img',
		jpg: 'img',
		jpeg: 'img'
	}
	const getIconByExtension = file => {
		const extension = file.split('.').pop().toLowerCase()
		return filesIcons[extension]
	}
	return (
		<Card>
			<h2 className={`title-question`}>
				<span className={`circle ${category}`}>{data.position}</span>
				<span className="title">{data.question}</span>
			</h2>
			<div className="container-option">
				<Input
					label="Respuesta"
					type="select"
					name={data.id}
					options={[{ label: data.answer.label, value: data.answer.id }]}
					placeholder="Selecciona una opción"
					value={{ label: data.answer.label, value: data.answer.id }}
				/>
				{data.answer.files?.length > 0 && (
					<div className="container-files">
						<span className="title-files-answers">Archivos</span>
						{data.answer.files.map((file, index) => (
							<a
								key={index}
								className="file-inline"
								href={URL_FILES + '/' + idAqueduct + '/' + pathId + '/' + file}
								target="_blank"
								rel="noreferrer"
								download>
								<img src={require(`assets/img/${getIconByExtension(file)}.png`)} alt="Archivo" />
								<span>{file}</span>
							</a>
						))}
					</div>
				)}
				<div className="answer-poinst">
					Puntos: {data.answer.points}/{data.points}
				</div>
			</div>
		</Card>
	)
}
