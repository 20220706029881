import { useState } from 'react'
import Input from 'components/Input'
import Btn from 'components/Btn'
import Link from 'components/Link'
import BtnGoogle from 'components/Auth/BtnGoogle'
import { setLoading, setAlert, setUser } from 'store'
import { requestHandler } from 'utils/requestHander'
import { useNavigate } from 'react-router-dom'

export default function Register() {
	const navigate = useNavigate()
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [passwordConfirmation, setPasswordConfirmation] = useState('')

	const handleRegister = async () => {
		if (!name || !email || !password) {
			setAlert({ type: 'danger', message: 'Todos los campos son obligatorios' })
			return
		}
		if (name.length < 3) {
			setAlert({ type: 'danger', message: 'El nombre es demasiado corto' })
			return
		}
		if (password.length < 6) {
			setAlert({ type: 'danger', message: 'La contraseña es demasiado corta' })
			return
		}
		if (password !== passwordConfirmation) {
			setAlert({ type: 'danger', message: 'Las contraseñas no coinciden' })
			return
		}
		setLoading(true)
		const result = await requestHandler('register', {
			name,
			email,
			password,
			type: 'manual'
		})
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				type: 'danger',
				message: result.data
			})
			return
		}
		setAlert({
			type: 'success',
			message: '¡Registro exitoso!'
		})
		setUser(result.data)
		navigate('/home')
	}

	return (
		<div className="auth-container">
			<div className="form">
				<div className="container-logos">
					<img src={require('assets/img/logo.png')} alt="Logo" />
					<img src={require('assets/img/logo-cornare.png')} alt="Logo" />
				</div>
				<div className="container-text">
					<h1>Registrarse</h1>
					<p>Ingresa tus datos para crear tu cuenta</p>
				</div>
				<Input
					type="text"
					name="name"
					label="Nombre"
					placeholder="Ingresa tu nombre"
					onChange={e => setName(e.target.value)}
					value={name}
					required
				/>
				<Input
					type="email"
					name="email"
					label="E-mail"
					placeholder="Ingresa tu e-mail"
					onChange={e => setEmail(e.target.value)}
					value={email}
					required
				/>
				<Input
					type="password"
					name="password"
					label="Contraseña"
					placeholder="Ingresa tu Contraseña"
					onChange={e => setPassword(e.target.value)}
					value={password}
					required
				/>
				<Input
					type="password"
					name="passwordConfirmation"
					label="Confirmar contraseña"
					placeholder="Confirma la contraseña"
					onChange={e => setPasswordConfirmation(e.target.value)}
					value={passwordConfirmation}
					required
				/>
				<Btn title="Registrarse" action={() => handleRegister()} />
				<Link title="Acceder" to={'/'} />
				<BtnGoogle />
			</div>
		</div>
	)
}
