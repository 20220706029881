import { useState, useEffect } from 'react'
import Card from 'components/Card'
import Row from 'components/Row'
import Input from 'components/Input'
import Btn from 'components/Btn'
import Table from 'components/Table'
import { setLoading, setAlert } from 'store'
import { requestHandler } from 'utils/requestHander'
import { useParams, useNavigate } from 'react-router-dom'
export default function CreateUpdateQuestion() {
	const navigate = useNavigate()
	const { id, category } = useParams()
	const [question, setQuestion] = useState('')
	const [points, setPoints] = useState('')
	const [currentOption, setCurrentOption] = useState('')
	const [options, setOptions] = useState([])
	const columns = [
		{ header: 'Opción', accessorKey: 'label' },
		{
			header: 'Puntos',
			accessorKey: 'points',
			cell: ({ row }) => (
				<InputPoints points={row.original.points} onChange={val => setPointsOption(row.original, val)} />
			)
		},
		{
			header: 'Carga de archivos',
			accessorKey: 'filesEnabled',
			cell: ({ row }) => (
				<InputLabelFiles
					checked={row.original.filesEnabled}
					valueLabel={row.original.filesLabel}
					onChangeChecked={val => setEnableFilesOption(row.original.id, val)}
					onChangeLabel={val => setFilesLabelOption(row.original.id, val)}
				/>
			)
		},
		{
			header: 'Acciones',
			actions: {
				delete: data => deleteOption(data)
			}
		}
	]

	const addOption = option => {
		// Que la opción no este vacia
		if (option === '') {
			setAlert({
				message: 'Ingresa una opción de respuesta',
				type: 'danger'
			})
			return
		}
		// Validar que la opcion no exista
		const idOption = option.toLowerCase().replace(/ /g, '_')
		const exist = options.find(option => option.id === idOption)
		if (exist) {
			setAlert({
				message: 'La opción ya existe',
				type: 'danger'
			})
			return
		}
		const dataOption = {
			id: idOption,
			label: option,
			points: 0,
			filesEnabled: false
		}
		const newOptions = [...options, dataOption]
		setOptions(newOptions)
		setCurrentOption('')
		setAlert({
			message: 'Opción agregada',
			type: 'success'
		})
	}
	const deleteOption = option => {
		const newOptions = options.filter(opt => opt.id !== option.id)
		setOptions(newOptions)
	}
	const setPointsOption = (option, value) => {
		const newOptions = options.map(opt => {
			if (opt.id === option.id) {
				opt.points = Number(value)
			}
			return opt
		})
		setOptions(newOptions)
	}
	const setEnableFilesOption = (id, value) => {
		const newOptions = options.map(opt => {
			if (opt.id === id) {
				opt.filesEnabled = value
			}
			return opt
		})
		setOptions(newOptions)
	}
	const setFilesLabelOption = (id, value) => {
		const newOptions = options.map(opt => {
			if (opt.id === id) {
				opt.filesLabel = value
			}
			return opt
		})
		setOptions(newOptions)
	}
	const sendData = async () => {
		if (question === '') {
			setAlert({
				message: 'Ingresa el texto de la pregunta',
				type: 'danger'
			})
			return
		}
		if (points === '' || points <= 0) {
			setAlert({
				message: 'Ingresa los puntos de la pregunta',
				type: 'danger'
			})
			return
		}
		if (options.length === 0) {
			setAlert({
				message: 'Ingresa al menos una opción de respuesta',
				type: 'danger'
			})
			return
		}
		for (const option of options) {
			// Valudaciones de puntos por opcion
			const value = Number(option.points)
			if (value === '') {
				setAlert({
					message: 'Ingresa los puntos',
					type: 'danger'
				})
				return
			}
			if (value > points) {
				setAlert({
					message: 'Los puntos no pueden ser mayores a los puntos de la pregunta',
					type: 'danger'
				})
				return
			}
			if (value < 0) {
				setAlert({
					message: 'Los puntos no pueden ser menores a 0',
					type: 'danger'
				})
				return
			}
		}

		const data = {
			question,
			points,
			options,
			category
		}
		if (id) {
			data.id = id
		}
		setLoading(true)
		const result = await requestHandler('create_update_question', data)
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				message: result.data,
				type: 'danger'
			})
			return
		}
		setAlert({
			message: 'Pregunta guardada',
			type: 'success'
		})
		navigate(-1)
	}
	const getData = async () => {
		setLoading(true)
		const result = await requestHandler('get_question', { id })
		setLoading(false)
		if (result.status !== true) {
			setAlert({
				message: result.data,
				type: 'danger'
			})
			return
		}
		setQuestion(result.data.question)
		setPoints(Number(result.data.points))
		setOptions(JSON.parse(result.data.options))
	}
	useEffect(() => {
		if (id) {
			getData()
		}
	}, [id])

	return (
		<Card title={id ? 'Actualizar pregunta' : 'Crear pregunta'}>
			<br />
			<Input
				type="textarea"
				name="question"
				required
				label="Texto de la pregunta"
				placeholder="Ingresa el texto de la pregunta"
				value={question}
				onChange={e => setQuestion(e.target.value)}
				style={{ marginRight: 25 }}
			/>
			<Input
				type="text"
				name="points"
				required
				label="Puntos maximos de la pregunta"
				placeholder="Ingresa los puntos maximos"
				value={points}
				onChange={e => setPoints(Number(e.target.value))}
			/>
			<div style={{ width: '100%', marginBottom: 15 }}>
				<Input
					type="text"
					name="options"
					label="Opciones de respuesta"
					subLabel="Presiona ENTER para agregar"
					placeholder="Ingresa una opción de respuesta"
					onChange={e => setCurrentOption(e.target.value)}
					value={currentOption}
					onEnter={e => addOption(e.target.value)}
				/>
				<Table columns={columns} data={options} pagination={false} filters={false} />
			</div>
			<Row>
				<Btn title="Volver" action={() => navigate(-1)} style={{ width: 200 }} />
				<Btn title="Guardar" action={() => sendData()} style={{ width: 200 }} />
			</Row>
		</Card>
	)
}

function InputPoints({ points, onChange }) {
	const [value, setValue] = useState(points)
	useEffect(() => {
		setValue(points)
	}, [points])

	return (
		<Input
			type="number"
			value={value}
			style={{ width: 120 }}
			onChange={e => setValue(e.target.value)}
			onBlur={() => onChange(value)}
		/>
	)
}

function InputLabelFiles({ checked, valueLabel, onChangeChecked, onChangeLabel }) {
	const [label, setLabel] = useState(valueLabel)
	useEffect(() => {
		setLabel(valueLabel)
	}, [valueLabel])

	return (
		<>
			<Input
				type="switch"
				label="Deshabilitar / Habilitar"
				value={checked}
				onChange={e => onChangeChecked(e.target.checked)}
			/>
			{checked && (
				<Input
					type="text"
					placeholder="Texto para carga de archivos"
					value={label}
					style={{ marginTop: 10 }}
					onChange={e => setLabel(e.target.value)}
					onBlur={() => onChangeLabel(label)}
				/>
			)}
		</>
	)
}
