function getURL() {
	if (process.env.NODE_ENV === 'development') {
		return 'http://localhost/aves-back/api.php' // Si estamos en desarrollo
	} else if (window.location.hostname.includes('plastimedia')) {
		return 'https://aves.plastimedia.com/api.php' // Si estamos en test
	} else {
		return 'http://localhost/aves-back/api.php' // Si estamos en producción
	}
}
const URL = getURL()

export const URL_FILES = URL.replace('api.php', 'answers')

export async function requestHandler(action, data) {
	try {
		const response = await fetch(URL, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				Authorization: localStorage.avesS,
				action,
				'Content-Type': 'application/json'
			}
		})
		const dataResponse = await response.json()
		// Si se vencio la sesion redirecciona al login
		if (dataResponse.code === 'UNAUTHENTICATED_USER') {
			localStorage.removeItem('avesS')
			window.location.href = '/login'
		}
		return dataResponse
	} catch (error) {
		console.error('Error en la petición', error)
		return {
			status: false,
			data: 'Ocurrió un error no majenado'
		}
	}
}
export async function requestHandlerFiles(action, data, pathId) {
	try {
		const response = await fetch(URL, {
			method: 'POST',
			body: data,
			headers: {
				action,
				pathId,
				Authorization: localStorage.avesS
			}
		})
		const dataResponse = await response.json()
		return dataResponse
	} catch (error) {
		console.error('Error en la petición', error)
		return {
			status: false,
			data: 'Ocurrió un error no majenado'
		}
	}
}
