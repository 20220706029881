import { useEffect, useState } from 'react'
import CreateUpdateAqueduct from 'views/Aqueducts/CreateUpdateAqueduct'
import WidgetBeingQuestionary from 'components/Questions/WidgetBeingQuestionary'
import WidgetResultsAqueduct from 'components/Dashboard/WidgetResultsAqueduct'
import WidgetGraphAndPosition from 'components/Dashboard/WidgetGraphAndPosition'
import WidgetsTopPositionsAndHistory from 'components/Dashboard/WidgetsTopPositionsAndHistory'
import WidgetWelcomeAndWaitForQuestionary from 'components/Dashboard/WidgetWelcomeAndWaitForQuestionary'
import Row from 'components/Row'
import Btn from 'components/Btn'
import { useNavigate } from 'react-router-dom'
import { requestHandler } from 'utils/requestHander'
import { percentages, getPercentageOf } from 'utils/mixins'
import { setAlert, setLoading } from 'store'
export default function Aqueduct({ user }) {
	const navigate = useNavigate()
	const [idLastAnswer, setIdLastAnswer] = useState(null)
	const [canEditLastAnswer, setCanEditLastAnswer] = useState(false)
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [version, setVersion] = useState('')
	const [currentAnswerVersion, setCurrentAnswerVersion] = useState('')
	const [showWidgetBegin, setShowWidgetBegin] = useState(false)
	const [hasAnswers, setHasAnswers] = useState(false)
	const [dataWidgetResults, setDataWidgetResults] = useState({})
	const [position, setPosition] = useState(null)
	const [totalPoints, settotalPoints] = useState(0)
	const [points, setPoints] = useState(0)
	const [top10, setTop10] = useState([])
	const [lastAnswers, setLastAnswers] = useState([])
	const getActiveQuestionary = async () => {
		setLoading(true)
		const result = await requestHandler('get_active_questionary_and_last_answers', {})
		setLoading(false)
		if (result.status !== true) {
			setAlert({ message: result.data, type: 'danger' })
			return
		}
		// Vamos a evaluar si el cuestionario esta activo y no ha sido respondido
		const settings = result.data.settings
		const lastAnswers = result.data.last_answer
		const now = Date.now()
		if (
			lastAnswers?.questionary_version !== settings.version &&
			now >= Number(settings.start_date) &&
			now <= Number(settings.end_date)
		) {
			setShowWidgetBegin(true)
			setStartDate(settings.start_date)
			setEndDate(settings.end_date)
			setVersion(settings.version)
		}
		// calculamos las ultimas respuestas
		if (lastAnswers !== null) {
			// Ambiental
			setDataWidgetResults({
				environmental: getPercentageOf(
					lastAnswers.total_environmental,
					percentages.environmental,
					lastAnswers.environmental
				),
				social: getPercentageOf(lastAnswers.total_social, percentages.social, lastAnswers.social),
				economic: getPercentageOf(lastAnswers.total_economic, percentages.economic, lastAnswers.economic),
				points: {
					environmental: lastAnswers.total_environmental,
					social: lastAnswers.total_social,
					economic: lastAnswers.total_economic,
					total: lastAnswers.total_points
				}
			})
			setCanEditLastAnswer(lastAnswers.finished !== 'Si')
			settotalPoints(lastAnswers.total_points)
			setPoints(Number(lastAnswers.environmental) + Number(lastAnswers.social) + Number(lastAnswers.economic))
			setPosition(result.data.position)
			setTop10(result.data.top_10)
			setLastAnswers(result.data.last_10_answers)
			setIdLastAnswer(lastAnswers.id)
			setHasAnswers(true)
			setCurrentAnswerVersion(lastAnswers.questionary_version)
		}
	}
	useEffect(() => {
		if (user.aqueduct) {
			getActiveQuestionary()
		}
	}, [user])

	if (user.aqueduct === null) {
		return <CreateUpdateAqueduct />
	}
	return (
		<>
			{!showWidgetBegin && !hasAnswers && <WidgetWelcomeAndWaitForQuestionary />}
			{showWidgetBegin && <WidgetBeingQuestionary startDate={startDate} endDate={endDate} version={version} />}
			{!showWidgetBegin && hasAnswers && (
				<>
					{idLastAnswer && (
						<Row>
							<Btn title="Ver respuestas" action={() => navigate(`/results/${idLastAnswer}`)} style={{ width: 200 }} />
							{canEditLastAnswer && (
								<Btn
									title="Editar respuestas"
									action={() => navigate(`/questionary/${idLastAnswer}`)}
									style={{ width: 200 }}
								/>
							)}
						</Row>
					)}
					<WidgetResultsAqueduct showCongratulations={true} data={dataWidgetResults} />
					<WidgetGraphAndPosition
						data={dataWidgetResults}
						position={position}
						totalPoints={totalPoints}
						points={points}
					/>
					<WidgetsTopPositionsAndHistory top10={top10} lastAnswers={lastAnswers} version={currentAnswerVersion} />
				</>
			)}
		</>
	)
}
